
import React from "react";

import { currentCourseStore } from '../../../stores/currentCourseStore';
import { observer } from "mobx-react-lite";
import classNames from "classnames";


const Instructions = (props) => {
	const {task} = props
	const currentTask = task ? task : currentCourseStore?.currentTask || {}
	const therapistProfileImage = currentCourseStore?.generalConfig?.therapist?.profileImageUrl
	const hasPersonalMsg = !!currentTask?.generalInfo?.personalMsg
	return (
		<React.Fragment>
			{hasPersonalMsg &&
				<div className="personal-msg__wrapper"> 
					<div className="avatar">
					<svg width="14" height="14" viewBox="0 0 14 14" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M3.53567 13.0021C3.37388 12.9248 3.29299 12.8088 3.29299 12.6542V10.3353H1.27067C1.028 10.3353 0.866211 10.1807 0.866211 9.9488V1.23243C0.866211 1.00054 1.028 0.845947 1.27067 0.845947H12.5956C12.8383 0.845947 13.0001 1.00054 13.0001 1.23243V9.9488C13.0001 10.1807 12.8383 10.3353 12.5956 10.3353H7.09494L3.98064 12.9634C3.89975 13.0021 3.77841 13.0407 3.69752 13.0407C3.657 13.0407 3.57611 13.0407 3.53567 13.0021Z" fill="white" stroke="#343434"/>
                    </svg>
						{therapistProfileImage && <img src={therapistProfileImage} alt='therapist Profile' />}
					</div>
					<div>
						{currentTask.generalInfo.personalMsg}
					</div>
				</div>

			}
			<div className={classNames(
				"instructions__wrapper mt-3", hasPersonalMsg ? 'with-personal-msg' : ''

			)}>
				<div className="section">
					<div className="section-title">Instructions:</div>
					<div className="instructions-content">
						{currentTask?.instructions?.map((instruction, idx) => (
							<div key={idx}>{instruction}</div>
						))}
					</div>
				</div>
				{currentTask?.hints &&
					<div className="section">
						<div className="section-title">Important tips:</div>
						<div className="tips-content">
							{currentTask.hints?.map((hint, idx) => (
								<div key={idx}>{hint}</div>
							))}
						</div>
					</div>
				}
			</div>
		</React.Fragment>

	);
};

export default observer(Instructions);