
import React, { useEffect } from "react";
import classnames from 'classnames';
import { PlayCircleFill, CheckCircleFill, PlayCircle } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Cookies from 'universal-cookie';

import {currentCourseStore} from '../../stores/currentCourseStore';
import WelcomeModal from "../../modals/WelcomeModal";
import Cover from '../../assets/images/Cover.png'
import GeneralFeedbackModal from "../../modals/GeneralFeedbackModal";


const cookies = new Cookies();


const CoursesPage = () => {
    const navigate = useNavigate();
    const currentCourseInfo = currentCourseStore?.currentCourseStoreData
    const showGeneralFeedbackModal = cookies.get('show-general-feedback') === 'true' && cookies.get('finished-session') === 'true'
    useEffect(() => {
          currentCourseStore.updateCurrentCourseData()
    }, []);

    console.log(currentCourseStore)
    const handleStartWorkout = () => {
        if (currentCourseStore.currentSession?.status === 0) {
            currentCourseStore.setCurrentSessionStatus(currentCourseStore.currentSession?.uuid, 1)
            .then((data) => {
                if(data.hasError) {
                    navigate('/login')
                }
            })
        }
        navigate('/session')
    }

  return (
    currentCourseInfo &&
    <div className="courses-page__wrapper row">
        {cookies.get('Show-welcome-dialog')  === 'true' && <WelcomeModal />}
        {showGeneralFeedbackModal && <GeneralFeedbackModal />}
        <div className="course-info-section">
            <div className="image-wrapper ">
                <img className="" src={Cover} alt={currentCourseInfo.title} />
            </div>
            <div className="course-details">
                <div className="course-name">{currentCourseInfo.title}</div>
                <div className="description">
                    {currentCourseInfo.description}
                </div>
            </div>
        </div>

        <div className="course-sessions__wrapper col-10">
        {currentCourseInfo && currentCourseInfo.sessions &&currentCourseInfo.sessions.map((session, idx) => {
            return (
                <div className="course-sessions__section" key={session.sessionIndex}>
                    <button
                        className={classnames({
                            'session-button': true,
                        })}
                        onClick={() => {
                            if(session.sessionIndex === currentCourseInfo.currentSession && session.status !== 2) {
                                handleStartWorkout()
                            }
                        }}
                    >
                    {session.status === 0 && session.sessionIndex !== currentCourseInfo.currentSession &&
                        <PlayCircle color="#9C9898" size={48} />
                    }
                    {session.sessionIndex === currentCourseInfo.currentSession && session.status !== 2 &&
                        <PlayCircleFill color="#23A377" size={48} />
                    }
                    {session.status === 2 &&
                        <CheckCircleFill color="#4678B4" size={48} />
                    }
                    </button>
                    <div>{`Day ${idx+1}`}</div>
                </div>
            )
        })
        }

        </div>
        {!(currentCourseInfo.status === 2 || (currentCourseInfo?.sessions?.length && currentCourseInfo?.sessions[currentCourseInfo.sessions.length-1]?.status === 2)) &&
            <div className="start-btn_wrapper sticky-bottom glow-effect col-12">
                <button className="btn btn-primary" onClick={() => handleStartWorkout()}>
                    Start workout
                </button>
            </div>
        }
    </div>
    
  );
};

export default observer(CoursesPage);