import React, { useEffect } from 'react';
import './App.scss';
import './styles/style.scss'
import { Routes, Route } from 'react-router-dom';
import LogInPage from './pages/Login';
import CoursesPage from './pages/CoursesInfo';
import SessionDetails from './pages/SessionDetails';
import Task from './pages/Task';
import FeedbackPage from './pages/Feedback';

import { observer } from 'mobx-react-lite';

import { useNavigate, useLocation } from 'react-router-dom';
import {currentCourseStore} from './stores/currentCourseStore';
import Cookies from 'universal-cookie';
import DoneView from './pages/Done';
import classNames from 'classnames';
import { logOutApi } from "./api/login";


const cookies = new Cookies();

function App() {

  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {
    if((currentCourseStore.currentCourseStoreData && Object.keys(currentCourseStore.currentCourseStoreData).length === 0)) {
      if(cookies.get('course-uuid')) {
        currentCourseStore.fetchCurrentCourse().then((data) => {
          if(data.hasError) {
            navigate('/login')
          } else {
            navigate('/courses')
          }
        })
      } else {
        navigate('/login')
      }
    }
  }, [location.pathname, navigate]);

  const handleLogOut = () => {
    logOutApi().then(() => {
        navigate('/login')
    })
}

  return (
      <div 
        className={classNames({
          'App container': true,
          'pb-0 position-relative': location.pathname === '/task',
        })}
      >

        {location.pathname !== '/login' && Object.keys(currentCourseStore.currentCourseStoreData).length &&
          <div className={classNames({
            'log-out': true,
            'overlap': location.pathname === '/task',
            'd-none': location.pathname === '/task' && (currentCourseStore.showPlayMode || currentCourseStore.showReplayview),
          })}
          >
              <button className="btn btn-primary" onClick={() => handleLogOut()}>
                  Log Out
              </button>
          </div>
        }
        <Routes>
          <Route path='/login' exact element={<LogInPage />} />
          <Route path='/courses' exact element={<CoursesPage />} />
          <Route path='/session' exact element={<SessionDetails />} />
          <Route path='/task' exact element={<Task />} />
          <Route path='/done' exact element={<DoneView />} />
          <Route path='/feedback' exact element={<FeedbackPage />} />
      </Routes>
      </div>
  );
}

export default observer(App);
