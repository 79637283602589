
import React from "react";

import { PlayFill, ArrowCounterclockwise } from 'react-bootstrap-icons';
import { currentCourseStore } from '../../../stores/currentCourseStore';



const ManualTransition = ({...props}) => {
    const {currentTask, repeatPreviousTask} = props

	return (
        <React.Fragment>
            <div className="task-transition-view__wrapper mt-4">
                {currentTask.title &&
                    <div className="task-transition-view__header">
                        <div className="">Next exercise:</div>
                        <div className="">{currentTask.title}</div>
                    </div>
                }
                {currentTask.image?.url &&
                    <div className="current-task-image">
                        <img src={currentTask.image.url} alt={currentTask.image?.title ? currentTask.image.title: currentTask.title} />
                    </div>
                }
                <div className="glow-effect sticky-bottom">
                    <div className="task-start-btn__wrapper d-flex justify-content-center">
                        <button className="btn btn-primary__light" onClick={() => repeatPreviousTask()} >
                            <ArrowCounterclockwise className="me-2" />
                            Replay previous exercise
                        </button>
                        <button className="btn btn-primary" onClick={() => currentCourseStore.updateShowPrepareMode(true)}>
                            <PlayFill className="me-2" />
                            Continue to next exercise
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
	);
};

export default ManualTransition;