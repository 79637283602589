
import React, {useEffect, useState} from "react";
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import {currentCourseStore} from '../stores/currentCourseStore';



const BackConfirmationModal = (props) => {
    const navigate = useNavigate();

    const {openModal, setOpenModal} = props
    const [show, setShow] = useState(openModal);

    useEffect(() => {
        setShow(openModal)

    }, [openModal]);

  return (
    <Modal
        show={show}
        onHide={() => {}}
        size='md'
        centered
    >
        <Modal.Body >
        <div className="back-confirmation__wrapper">
            <div className="title">
                I’m done for today
            </div>
            <div className="mt-4 d-flex">
				<button className="btn btn-tertiary me-2" onClick={() => {
                    setShow(false)
                    setOpenModal(false)
                    currentCourseStore.updateShowPrepareMode(true)
                    currentCourseStore.setExercisePause(false)
                    navigate(`/session`)
                }}>
                    Exit workout
				</button>
                <button className="btn btn-tertiary ms-2" onClick={() => {
                    setShow(false)
                    setOpenModal(false)
                    currentCourseStore.setDoneSessionInfo({
                        uuid: currentCourseStore.currentSession.uuid,
                        sessionIndex: currentCourseStore.currentSession.sessionIndex
                    })
                    const isLastsession = currentCourseStore?.currentSession.sessionIndex === currentCourseStore?.currentCourseStoreData?.sessionsCount
                    currentCourseStore.setCurrentSessionStatus(currentCourseStore.currentSession.uuid, 2, isLastsession)
                        .then((data) => {
                            if(data.hasError) {
                                navigate('/login')
                            }
                        })
                    currentCourseStore.updateShowPrepareMode(true)
                    currentCourseStore.setExercisePause(false)
                    navigate(`/done`)
                }}>
                    Mark today complete
				</button>
			</div>
            <div className="mt-4">
				<button className="btn btn-primary" onClick={() => {
                    setShow(false)
                    setOpenModal(false)
                    currentCourseStore.setExercisePause(false)
                }}>
                    Keep going
				</button>
			</div>
        </div>
        </Modal.Body>

    </Modal>
  );
};

export default observer(BackConfirmationModal);
