
import React, {useEffect, useState} from "react";
import { observer } from 'mobx-react-lite';
import Modal from 'react-bootstrap/Modal';
import {currentCourseStore} from '../stores/currentCourseStore';
import { ChevronRight, ChevronLeft } from 'react-bootstrap-icons';

import Instructions from "../pages/Task/partials/instructions";



const InstructionModal = (props) => {

    const {openModal, setOpenModal, currentnidx = -1 , currentTask, hideNavArrow = false} = props
    const tasks = currentCourseStore?.allTasks

    const [show, setShow] = useState(openModal);
    const [currentTaskIndex, SetCurrentTaskIndex] = useState(currentnidx)
    const [currentTaskData, SetCurrentTaskData] = useState(hideNavArrow ? currentTask : tasks[currentnidx])

    const showAnotherTask = (taskIdx) => {
        SetCurrentTaskIndex(taskIdx)
        SetCurrentTaskData(tasks[taskIdx])
    }

    useEffect(() => {
        setShow(openModal)
        if (openModal) {
            if (currentTaskIndex >= 0) {
                SetCurrentTaskIndex(currentTaskIndex)
                SetCurrentTaskData(hideNavArrow ? currentTask : tasks[currentTaskIndex])
            } else {
                SetCurrentTaskIndex(currentnidx)
                SetCurrentTaskData(hideNavArrow ? currentTask : tasks[currentnidx])
            }
        }

    }, [openModal, currentnidx, tasks, currentTaskData, currentTaskIndex, hideNavArrow, currentTask]);


  return (
    <Modal
        show={show}
        onHide={() => {
            setShow(false)
            setOpenModal(false)
            SetCurrentTaskIndex(-1)
            SetCurrentTaskData({})
        }}
        size='lg'
        centered
    >
        <Modal.Header closeButton />
        <Modal.Body >
        <div className="instuction-modal__wrapper d-flex justify-content-center ">
            {!hideNavArrow &&
                <div className="action-btn me-3">
                    <button
                        className="icon-btn"
                        onClick={() => showAnotherTask(currentTaskIndex-1)}
                        disabled={currentTaskIndex === 0}
                    >
                        <ChevronLeft color="#343434" size={20} />
                    </button>
                </div>
            }
            <div className="content-wrapper row">
                <div className="image col-6"><img src={currentTaskData?.image?.url} alt={currentTaskData?.image?.title} /></div>
                <div className="content col-6">
                    <div className="title">
                        {currentTaskData?.title}
                    </div>
                    <div className="instruction">
                    {currentTaskData &&<Instructions task={currentTaskData} />}
                    </div>
                </div>
            </div>
            {!hideNavArrow &&
                <div className="action-btn ms-3">
                    <button
                        className="icon-btn"
                        onClick={() => showAnotherTask(currentTaskIndex+1)}
                        disabled={currentTaskIndex === (tasks.length - 1)}
                    >
                        <ChevronRight color="#343434" size={20} />
                    </button>
                </div>
            }
        </div>
        </Modal.Body>

    </Modal>
  );
};

export default observer(InstructionModal);
