import React from "react";
import Cookies from 'universal-cookie';


const cookies = new Cookies();

const InfoPopup = (props) => {
    const {setShow} = props
    return (
      <div className="info-popup__wrapper">
        <div className="title">
            Autoplay or one-by-one
        </div>
        <div className="content">
            <span className="pb-4">Autoplay on means exercises will play one after the other.</span>
            <span>Autoplay off allows you to choose when the next exercise starts.</span>
        </div>
        <div className="mt-4">
				<button className="btn btn-primary" onClick={() => {
                    cookies.set('Show-popup', false)
                    setShow(false)
                }}>
                  Got it!
				</button>
			</div>
      </div>

    );
  }

export default InfoPopup
