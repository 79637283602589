
import React, {useState} from "react";
import { useNavigate } from 'react-router-dom';
import { currentCourseStore } from '../../stores/currentCourseStore';
import { observer } from "mobx-react-lite";
import ManualPrepareView from "./partials/manualPrepareView";
import ManualTransition from "./partials/manualTransition";
import PlayTaskView from "./partials/playTaskView";
import RePlayTaskView from "./partials/rePlayTaskView";
import EndExerciseSound from "../../components/EndExerciseSound";



const ManualModeTask = () => {
	const navigate = useNavigate();

	const [pauseTask, setPauseTask] = useState(false)
	const [repeatTask, setRepeatTask] = useState(false)
	const [playSound, setPlaySound] = useState(false);

	const currentTask = currentCourseStore?.currentTask
	const currentTaskIndex = currentCourseStore?.currentTaskIndex
	const currentSession = currentCourseStore?.currentSession
	const allTasks = currentCourseStore.allTasks
	const showReplayview = currentCourseStore.showReplayview


	const PrepareTimerDone = () => {
		currentCourseStore.updateShowPlayMode(true)
	}

	const taskTimerDone = () => {
		if (showReplayview) {
			currentCourseStore.updateShowPrepareMode(true)
			navigate('/session')
		} else {
			// go to the next task
			if(currentTaskIndex === allTasks.length) {
				//update status for the session using api/v4/sessions/{sessionUUID}
				currentCourseStore.setCurrentTaskStatus(currentTask.generalInfo.uuid)
				.then((data) => {
					if(data.hasError) {
						navigate('/login')
					}
				})
				currentCourseStore.setDoneSessionInfo({
					uuid: currentSession.uuid,
					sessionIndex: currentSession.sessionIndex
				})
				const isLastsession = currentSession.sessionIndex === currentCourseStore?.currentCourseStoreData?.sessionsCount
				currentCourseStore.setCurrentSessionStatus(currentSession.uuid, 2, isLastsession)
				.then((data) => {
					if(data.hasError) {
						navigate('/login')
					}
				})
				navigate('/done')
			} else {
				currentCourseStore.setCurrentTaskStatus(currentTask.generalInfo.uuid)
				.then((data) => {
					if(data.hasError) {
						navigate('/login')
					}
				})
				currentCourseStore.updateCurrentTask(allTasks[currentTaskIndex])
				// currentCourseStore.updateShowPrepareMode(true)
				currentCourseStore.updateCurrentTaskIndex(currentTaskIndex+1)
			}
		}
	}
	const goBack = () => {
		if(currentTaskIndex === 1) {
			//update status for the session using api/v4/sessions/{sessionUUID}
			navigate('/session')
		} else {
			currentCourseStore.updateCurrentTask(allTasks[currentTaskIndex-2])
			currentCourseStore.updateShowPrepareMode(true)
			currentCourseStore.updateCurrentTaskIndex(currentTaskIndex-1)
		}
	}

	const repeatPreviousTask = () => {
		if(currentTaskIndex === 1) {
			//update status for the session using api/v4/sessions/{sessionUUID}
			navigate('/session')
		} else {
			currentCourseStore.updateCurrentTask(allTasks[currentTaskIndex-2])

			currentCourseStore.updateCurrentTaskIndex(currentTaskIndex-1)
			currentCourseStore.updateShowPlayMode(true)
			// ToDo update status for the task using api/v4/tasks/{taskUUID}
		}
	}

	return (
		<div className="task-view-page">
		<div className="">
			<EndExerciseSound playSound={playSound} setPlaySound={setPlaySound} />
			{currentCourseStore.showReplayview &&
				<RePlayTaskView currentTask={currentTask} setRepeatTask={setRepeatTask} repeatTask={repeatTask} pauseTask={pauseTask} taskTimerDone={taskTimerDone} goBack={goBack} setPauseTask={setPauseTask} setPlaySound={setPlaySound} />
			}
			{!showReplayview && currentCourseStore.showPrepareMode &&
			// show normal flow
				<ManualPrepareView currentSession={currentSession} currentTaskIndex={currentTaskIndex} currentTask={currentTask} PrepareTimerDone={PrepareTimerDone} repeatPreviousTask={repeatPreviousTask} />
			}
			{!showReplayview && currentCourseStore.showTransitionMode &&//show transition
				<ManualTransition currentTask={currentTask} PrepareTimerDone={PrepareTimerDone} repeatPreviousTask={repeatPreviousTask} />
			}
			{!showReplayview && currentCourseStore.showPlayMode && // show play
				<PlayTaskView currentTask={currentTask} setRepeatTask={setRepeatTask} repeatTask={repeatTask} pauseTask={pauseTask} taskTimerDone={taskTimerDone} goBack={goBack} setPauseTask={setPauseTask} setPlaySound={setPlaySound} />
			}
		</div>

	</div>
	);
};

export default observer(ManualModeTask);