import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const parseLoginRes = (data) => {
    if (data && data.config) {
        const loginConfig = {
            apiToken: data.apiToken,
        }
        const currentCourseData = {
            uuid: data.config.currentCourse.uuid,
            status: data.config.currentCourse.status
        }
        const patientInfo = {
            firstName: data.config.firstName,
            patientEmail: data.patientEmail,
            patientUuid: data.patientUuid,
            lastName: data.config.lastName,
        }

        const therapist = {
            ...data.config.therapist
        }
        return {loginConfig, currentCourseData, patientInfo, therapist}
    }
}


export const refreshToken = async () => {
    const _url ="/api/v4/auth/login/token/refresh";
    return new Promise(async (resolve, reject) => {
        try {
            const res = await axios.post(_url, {} , {
            headers: {
                'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
            },
            });
            const parsedRes = parseLoginRes(res.data)
            if(parsedRes?.therapist?.uuid) {
                getThuerapistData(parsedRes.therapist.uuid).then((data)=>{
                    const therapist = {
                        ...parsedRes.therapist,
                        profileImageUrl: data.profileImageUrl
                    }
                    resolve({...parsedRes, therapist})
                }

                )
            } else {
                resolve({...parsedRes})
            }
        } catch (error) {
            resolve({hasError: true})
        }
    });
}

export const handleLoginRes = (resData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const parsedRes = parseLoginRes(resData)
            if(parsedRes?.therapist?.uuid) {
                getThuerapistData(parsedRes.therapist.uuid).then((data)=>{
                    const therapist = {
                        ...parsedRes.therapist,
                        profileImageUrl: data.profileImageUrl
                    }
                    resolve({...parsedRes, therapist})
                }

                )
            } else {
                resolve({...parsedRes})
            }
        } catch (error) {
        }
    })
}

export const loginApi = async (otp) => {
    const _url ="/api/v4/auth/login/token";
    const loginBody = {
        otp
    }
    return new Promise(async (resolve, reject) => {
        try {
            const res = await axios.post(_url, (loginBody), {
            headers: {
                'content-type': 'application/json'
            },
            });
            if (res) {
                cookies.set('first_login_time', new Date().getTime())
                resolve(handleLoginRes(res.data))
            }
            // }
        } catch (err) {
            resolve({hasError: true})
        }
    });

}


export const logOutApi = async () => {
    const _url ="/api/v4/auth/login/token";
    return new Promise(async (resolve, reject) => {
        try {
           await axios.delete(_url);
           cookies.remove('first_login_date')
           resolve(true)
        } catch (err) {
            resolve({hasError: true})
        }
    });

}

export const getThuerapistData = async (uuid) => {
    const _url =`/api/v4/therapist/${uuid}`
    return new Promise(async (resolve, reject) => {
        try {
            const res = await axios.get(_url, {
            });
            resolve(res.data)
        } catch (error) {
            // return error;
        }
    });

}
