
import React, {useState, useEffect} from "react";
import ImportantTipsModal from "../../../modals/importantTipsModal";

import Instructions from "./instructions";


const ManualPrepareView = (props) => {
    const {currentSession, currentTaskIndex, currentTask, PrepareTimerDone} = props
    const hasHints = !!currentTask?.hints?.length
	const [openHintsModal, setOpenHintsModal] = useState(false)

    useEffect(() => {
        let tipTimeOut
		if(hasHints && openHintsModal) {
			tipTimeOut  = setTimeout(() => {
				setOpenHintsModal(false)
                PrepareTimerDone()
                console.log('here')
			}, 5500)
		} else {
            clearTimeout(tipTimeOut)
        }
        return () => {
            clearTimeout(tipTimeOut);
        };
	  }, [PrepareTimerDone, hasHints, openHintsModal]);

	return (
        <React.Fragment>
            <div className="task-prepare-view__wrapper manual my-4">
                <div className="d-flex justify-content-center">
                    {currentTask.image?.url &&
                        <div className="current-task-image__container">
                            <div className="view-header">
                                <span className="related-session">Day {currentSession.sessionIndex+1}</span>
                                <span>-</span>
                                <span className="task-position">Exercise {currentTaskIndex} of {currentSession && currentSession.tasks?.length}</span>
                            </div>
                            <div className="current-task-image">
                                <img src={currentTask.image.url} alt={currentTask.image?.title ? currentTask.image.title: currentTask.title} />
                            </div>
                        </div>
                    }
                    <div className="content-wrapper">
                        {currentTask.title &&
                            <div className="task-name">
                                <div className="task-name__value">{currentTask.title}</div>
                            </div>
                        }
                        <Instructions />
                    </div>
                </div>
                <div className="task-start-btn__wrapper sticky-bottom glow-effect">
                    <button className="btn btn-primary" onClick={() => {
                        if(hasHints) {
                            setOpenHintsModal(true)
                        } else {
                            PrepareTimerDone()
                        }
                        }}>
                        Start exercise
                    </button>
                </div>
            </div>
            {hasHints &&
                <ImportantTipsModal
                    openHintsModal={openHintsModal}
                    setOpenHintsModal={setOpenHintsModal}
                    PrepareTimerDone={PrepareTimerDone}
                    hints={currentTask?.hints} />}
        </React.Fragment>
	);
};

export default ManualPrepareView;