
import React from "react";
const ReplaySvg = () => {
  return (
    <div className="replay-svg">
        <svg width="20" height="22" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M23.6087 15.5614C23.5943 19.0387 22.031 22.3219 19.3564 24.4914C16.6816 26.6598 13.1852 27.4815 9.8446 26.7245C6.50432 25.9687 3.68174 23.7148 2.16647 20.5967C0.652473 17.4784 0.610424 13.8313 2.05112 10.6778C2.19171 10.3579 2.45246 10.1101 2.77449 9.98798C3.09652 9.86588 3.45457 9.88176 3.76581 10.0295C4.07701 10.1784 4.31614 10.4483 4.43029 10.7779C4.54444 11.1076 4.52281 11.4702 4.37022 11.784C3.81988 12.9646 3.53513 14.2552 3.53753 15.5616C3.53392 17.9083 4.44592 20.1623 6.07287 21.8276C7.69982 23.4942 9.90963 24.4392 12.22 24.4551C14.5295 24.4709 16.7523 23.5564 18.4011 21.913C20.0496 20.2684 20.9905 18.0279 21.0193 15.6812C21.0469 13.3333 20.1602 11.0709 18.5513 9.38583C16.9423 7.70215 14.7422 6.7327 12.4327 6.69241L13.2654 7.55196C13.6331 7.92191 13.7448 8.48234 13.549 8.9695C13.3531 9.45666 12.8869 9.77653 12.3678 9.77897C12.0254 9.77653 11.6985 9.63612 11.4582 9.38827L8.45908 6.36641C8.20074 6.11978 8.05295 5.77548 8.05295 5.41528C8.05295 5.05511 8.20074 4.71079 8.45908 4.46537L11.4967 1.37881C11.9989 0.870896 12.8112 0.874553 13.3099 1.38492C13.8097 1.89527 13.8061 2.72062 13.3038 3.22732L12.4579 4.08687C15.4258 4.13204 18.2578 5.35909 20.344 7.50568C22.4299 9.65211 23.6028 12.5445 23.6086 15.5614L23.6087 15.5614Z" fill="#23A377" stroke="#23A377"/>
        </svg>

    </div>
  );
};

export default ReplaySvg;