import React, { useRef, useEffect, useState } from "react";

const PlayAudio = ({ ...props }) => {

	const { src, pauseAduio, repeat, repeatDone } = props
	const audioRef1 = useRef();
	const [newAudio, setNewAudio] = useState(false)

	const handlePlay = () => {
		this.audioRef1.current.play();
		audioRef1.current.volume = 0.2;
		this.audioRef1.current.muted = false;
	};

	useEffect(() => {
		setNewAudio(true)
		audioRef1.current
			.play()
		audioRef1.current.volume = 0.2;
	}, [src]);

	useEffect(() => {
		if (repeat) {
			audioRef1.current.currentTime = 0
			audioRef1.current.volume = 0.2;
			if (!pauseAduio) {
				audioRef1.current
					.play()
			}
			repeatDone()
		}

	}, [pauseAduio, repeat, repeatDone]);

	useEffect(() => {
		try {
			if (newAudio) {
				audioRef1.current
					.play()
				audioRef1.current.volume = 0.2;

			}
			if (!pauseAduio && !newAudio) {
				audioRef1.current
					.play()
			} else if (pauseAduio) {
				audioRef1.current
					.pause()
			}

			if (audioRef1.current.currentTime > 0.5) {
				setNewAudio(false)

			}

		} catch (error) {
			console.log("try-catch fails");
		}
	}, [audioRef1, newAudio, pauseAduio]);

	return (
		<div onTouchStart={() => handlePlay()}>
			<audio
				className="d-none"
				ref={audioRef1}
				id="1"
				controls
				loop={false}
				src={src}
			/>
		</div>

	);
}

export default PlayAudio
