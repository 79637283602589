
import React from "react";
import { useNavigate } from 'react-router-dom';
import {currentCourseStore} from '../../stores/currentCourseStore';
import { StarFill } from 'react-bootstrap-icons';
import Placeholder from 'react-bootstrap/Placeholder';

import sessionDone from '../../assets/images/sessionDone.png'
import courseDone from '../../assets/images/courseDone.png'



import { observer } from 'mobx-react-lite';
import Cookies from 'universal-cookie';



const cookies = new Cookies();

const DoneView = () => {
    const navigate = useNavigate();
    const currentCourseInfo = currentCourseStore?.currentCourseStoreData
    const doneSessionInfo = currentCourseStore?.doneSessionInfo
    const currentSession = doneSessionInfo?.sessionIndex
    const sessionsCount = currentCourseInfo.sessionsCount
    const isCourseDone = currentSession + 1 === sessionsCount
    const isLoading = currentCourseStore.isLoading

    const stars = []
    for (let i = 0; i < sessionsCount; i++) {
        if (i <= currentSession ) {
            stars.push(<StarFill color="#FFD20B" size={30} key={i} />);
        } else {
            stars.push(<StarFill color="#CECACA" size={30} key={i} />);
        }
    }

  return (
    <div className="done-viwe__wrapper">
        {isLoading ?
        <React.Fragment>
            <div className="title">
                <Placeholder style={{ width: '25%', height:'30px' }} animation="wave" />
            </div>
            <div className="subtitle">
                <Placeholder style={{ width: '40%', height:'30px' }} animation="wave" />
            </div>
            <div className="img-wrapper">
                <Placeholder style={{ width: '375px', height:'280px' }} animation="wave" />
            </div>
            <div className="done-work-msg">
                <Placeholder style={{ width: '40%', height:'30px' }} animation="wave" />
            </div>
            <div className="stars-wrapper">
                <Placeholder style={{ width: '50%', height:'30px' }} animation="wave" />
            </div>
            <div className="mt-4">
                <Placeholder style={{ width: '35%', height:'68px' }} animation="wave" />
			</div>
        </React.Fragment>
        :
        <React.Fragment>
            <div className="title">
                {isCourseDone? "Hurray! Course Completed" : "Great Job!"}
            </div>
            <div className="subtitle">
                {isCourseDone? `This was the last day of ${currentCourseInfo.title}.` : "You completed your exercises for today."}
            </div>
            <div><img src={isCourseDone? courseDone: sessionDone} alt='Great Job'/></div>
            <div className="done-work-msg">
                Day {currentSession + 1} of {sessionsCount} completed!
            </div>
            <div className="stars-wrapper">
                {stars}
            </div>
            <div className="sticky-bottom glow-effect">
				<button className="btn btn-primary" onClick={() => {
                    // setShow(false)
                    // setOpenModal(false)
                    cookies.set('finished-session', true)
                    navigate(`/feedback`)
                }}>
                    Continue
				</button>
			</div>
        </React.Fragment>
        }

        </div>
  );
};

export default observer(DoneView);