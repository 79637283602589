
import React from "react";
import { observer } from 'mobx-react-lite';
import Modal from 'react-bootstrap/Modal';
import Timer from "../components/CountDown";

const ImportantTipsModal = (props) => {
    const {openHintsModal, setOpenHintsModal, PrepareTimerDone, hints} = props

    const handleClosePopup = () => {
        setOpenHintsModal(false)
        PrepareTimerDone()
    }

    return (
        <Modal
        show={openHintsModal}
        onHide={() => {
            handleClosePopup()
        }}
        size='md'
        centered
    >
        <Modal.Body >
            <div className="tips__modal p-4">
                <div className="section p-2">
                    <div className="section-title">Important tips:</div>
                    <div className="tips-content">
                        {hints?.map((hint, idx) => (
                            <div key={idx}>{hint}</div>
                        ))}
                    </div>
                </div>
                <Timer period={5} pauseTimer={false} repeat={false} repeatDone={()=>{}} timerDone={()=> {}} setPlaySound={()=>{}} playSound={false} />
                <button className="btn btn-primary w-50 mt-3" onClick={() => {
                    handleClosePopup()
                }} >
                    Got it!
                </button>
            </div>
        </Modal.Body>

    </Modal>
  );
};

export default observer(ImportantTipsModal);
