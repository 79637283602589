import axios from 'axios';
import { runInAction, makeAutoObservable, action, observable } from 'mobx';
import { isEmpty } from 'lodash';
// import qs from 'qs'
import Cookies from 'universal-cookie';

const refreshPeriod = 3
const cookies = new Cookies();
class CurrentCourseStore {
	currentCourseStoreData = {};
	currentSession = {}
	baseTase = {}
	sessionRelatedEquipments = []
	test =[]
	isLoading = true;
	currentTask = {}
	currentTaskIndex = -1
	allTasks = []
	showReplayview = false
	generalConfig = {}
	isAutoplayMood = false
	showTransitionMode = false
	showPlayMode = false
	showPrepareMode = true
	exercisePause = false
	doneSessionInfo = {}

	constructor() {
		makeAutoObservable(this, {
			currentCourseStoreData: observable,
			fetchCurrentCourse: action,
			baseTase: observable,
			fetchBaseTask: action,
			currentSession: observable,
			updateSessionEquipments:action,
			sessionRelatedEquipments: observable,
			test: observable,
			currentTask: observable,
			updateCurrentTask: action,
			updateCurrentTaskIndex: action,
			currentTaskIndex: observable,
			allTasks: observable,
			updateSessionTasks: action,
			setCurrentTaskStatus: action,
			showReplayview: observable,
			updateShowReplayview: action,
			generalConfig: observable,
			updateGeneralConfig: action,
			isAutoplayMood: observable,
			updateAutoplay: action,
			updateCurrentCourseSessionIdx: action,
			showTransitionMode: observable,
			showPlayMode: observable,
			updateShowTransitionMode: action,
			updateShowPlayMode: action,
			updateShowPrepareMode: action,
			showPrepareMode: observable,
			setExercisePause: action,
			exercisePause: observable,
			doneSessionInfo: observable,
			setDoneSessionInfo: action,
			updateCurrentCourseData : action,
			refreshToken: action,
		});
	}

	async refreshToken() {
		const _url = `/api/v4/auth/login/token/refresh`;
		this.isLoading = true;
		const result = await axios.post(_url, {} , {
			headers: {
				'content-type': 'application/json'
			},
			});

		runInAction(() => {
			this.isLoading = false;
			if (result && result.data) {
			 //
			}
		});
	}

	async updateCurrentCourseData() {
		const _url = `/api/v4/config`;
		this.isLoading = true;
		const result = await axios.get(_url, {
            // headers: {
            //     Authorization: `Bearer ${this.generalConfig.token}`,
            // },
            });

		runInAction(() => {
			this.isLoading = false;
			if (result && result.data) {
				this.generalConfig.currentCourseUuid = result.data?.currentCourse?.uuid || this.generalConfig.currentCourseUuid
				cookies.set('course-uuid', this.generalConfig.currentCourseUuid)
				this.getThuerapistData(result.data?.therapist?.uuid)
				this.fetchCurrentCourse()

				const sessionTimeOut = cookies.get('auth_timeout')
				const sessionTimeOutInDay = sessionTimeOut / 86400000
				const firstLoginDate = 	cookies.get('first_login_time')
				const differenceInTime =  (new Date().getTime()) - firstLoginDate
				const sessionTimeInDay = differenceInTime / (1000 * 3600 * 24);
				console.log('sessionTimeInDay', sessionTimeOutInDay - sessionTimeInDay)
				if((sessionTimeOutInDay - sessionTimeInDay) < refreshPeriod ) {
					this.refreshToken()
				}
			}
		});
	}

	async getThuerapistData (uuid) {
		const _url =`/api/v4/therapist/${uuid}`
		return new Promise(async (resolve, reject) => {
			try {
				const res = await axios.get(_url, {
				});
				runInAction(() => {
					resolve(res.data)
					this.generalConfig.therapist = {
						...res.data,
						profileImageUrl: res.data?.profileImageUrl
					}
				})
			} catch (error) {
				// return error;
			}
		});
	
	}

	async fetchCurrentCourse(forceReload = false) {
		const _url = `/api/v4/courses/${cookies.get('course-uuid')}`;
		this.isLoading = true;
		return new Promise(async (resolve, reject) => {
			try {
				const result = await axios.get(_url, {
					});

					runInAction(() => {
						this.isLoading = false;
						this.currentCourseStoreData = result?.data;
						// eslint-disable-next-line array-callback-return
						result?.data.sessions && result.data.sessions.map((session) => {
							if (session.sessionIndex === result.data.currentSession) {
								this.currentSession = session
							}
						})
						if (result && result.data) {
							this.fetchBaseTask()
						}
						resolve({hasError: false})
					});
			} catch (error) {
				resolve({hasError: true})
			}
		});
	}

	async fetchBaseTask() {
		if (!isEmpty(this.baseTase)) {
			return;
		}
		const _url = `/api/v4/base_tasks`;
		this.isLoading = true;

		return new Promise(async (resolve, reject) => {
			try {
				const result = await axios.get(_url, {
				});

				runInAction(() => {
					this.isLoading = false;
					const allEquipments = result?.data.equipment;
					const allTasks = result?.data.result;
					this.baseTase = {allEquipments, allTasks};
				});
			} catch (error) {
				resolve({hasError: true})
			}
		})
	}

	async setCurrentSessionStatus(uuid, status, isLastSession) {
		const _url = `/api/v4/sessions/${uuid}`;
		this.isLoading = true;
		return new Promise(async (resolve, reject) => {
			try {
				const result = await axios.patch(_url, {status: status} ,{
				});
				runInAction(() => {
					this.isLoading = false;
					if (result && result.data) {
						this.fetchCurrentCourse(true)
						this.fetchBaseTask()
						if (status === 2) {
							this.updateCurrentCourseSessionIdx()
							if (isLastSession) {
								this.updateCurrentCourseStatus()
							}
						}
					}
				});
			} catch (error) {
				resolve({hasError: true})
			}
		})
	}


	async updateCurrentCourseSessionIdx() {
		const _url = `/api/v4/courses/${cookies.get('course-uuid')}`;
		this.isLoading = true;
		return new Promise(async (resolve, reject) => {
			try {
				const result = await axios.patch(_url, {currentSession: this.currentSession.sessionIndex+1} ,{
				});
				runInAction(() => {
					this.isLoading = false;
					if (result && result.data) {
						this.fetchCurrentCourse()
						this.fetchBaseTask()
					}
				});
			} catch (error) {
				resolve({hasError: true})
			}
		})
	}

	async updateCurrentCourseStatus() {
		const _url = `/api/v4/courses/${cookies.get('course-uuid')}`;
		this.isLoading = true;
		return new Promise(async (resolve, reject) => {
			try {
				const result = await axios.patch(_url, {status: 2} ,{
				});
				runInAction(() => {
					this.isLoading = false;
					if (result && result.data) {
						this.fetchCurrentCourse()
						this.fetchBaseTask()
					}
				});
			} catch (error) {
				resolve({hasError: true})
			}
		})
	}


	async setCurrentTaskStatus(uuid) {
		const _url = `/api/v4/tasks/${uuid}`;
		this.isLoading = true;
		return new Promise(async (resolve, reject) => {
			try {
				const result = await axios.patch(_url, ({status: 1}) ,{
				});

				runInAction(() => {
					this.isLoading = false;
					if (result && result.data) {
						this.currentCourseStoreData = {}
						this.baseTase = {}
						this.fetchCurrentCourse()
						this.fetchBaseTask()
					}
				});
			} catch (error) {
				resolve({hasError: true})
			}
		})
	}

	  updateCurrentTask(data) {
		this.currentTask = data
	  }

	  updateSessionTasks(data) {
		this.allTasks = data
	  }

	  updateCurrentTaskIndex(data) {
		this.currentTaskIndex = data
	  }

	  updateShowReplayview(data) {
		this.showReplayview = data
		this.showTransitionMode = false
		this.showPlayMode = false
		this.showPrepareMode = false
	  }

	  updateAutoplay(data) {
		this.isAutoplayMood = data
	  }

	  updateShowTransitionMode(data) {
		this.showTransitionMode = data
		this.showPlayMode = false
		this.showPrepareMode = false
		this.showReplayview = false
	  }
	  updateShowPlayMode(data) {
		this.showTransitionMode = false
		this.showPlayMode = data
		this.showPrepareMode = false
		this.showReplayview = false
	  }
	  updateShowPrepareMode(data) {
		this.showTransitionMode = false
		this.showPlayMode = false
		this.showPrepareMode = data
		this.showReplayview = false
	  }

	  updateGeneralConfig(data) {
		this.generalConfig = data
	  }

	  setExercisePause(data) {
		this.exercisePause = data
	  }

	  setDoneSessionInfo(data) {
		this.doneSessionInfo = data
	  }
}

export const currentCourseStore = new CurrentCourseStore()
