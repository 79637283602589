import axios from 'axios';

export const sessionFeedbackApi = async (uuid, selectedTags, comment) => {
    const _url =`api/v4/sessions/${uuid}`;
    const body = {
        feedback : {
            tags:selectedTags,
            comment
        }
    }
    return new Promise(async (resolve, reject) => {
        try {
            const res = await axios.patch(_url, body, {
            });
            const parsedRes = res.data
            resolve({...parsedRes})
        } catch (error) {
            resolve({hasError: true})
        }
    });
}

export const taskFeedbackApi = async (uuid, selectedTags, comment = '') => {
    const _url =`api/v4/tasks/${uuid}`;
    const body = {
        feedback : {
            tags:selectedTags,
            comment
        }
    }
    return new Promise(async (resolve, reject) => {
        try {
            const res = await axios.patch(_url, body, {
            });
            const parsedRes = res.data
            resolve({...parsedRes})
        } catch (error) {
            resolve({hasError: true})
        }
    });
}
