
import React, {useState} from "react";
import { observer } from 'mobx-react-lite';
import Modal from 'react-bootstrap/Modal';
import welcome from '../assets/images/welcome.png'
import {currentCourseStore} from '../stores/currentCourseStore';
import Cookies from 'universal-cookie';


const cookies = new Cookies();


const WelcomeModal = () => {

    const [show, setShow] = useState(true);
    return (
    <Modal
        show={show}
        onHide={() => {
            setShow(false)
            cookies.set('Show-welcome-dialog', false)
        }}
        size='md'
        centered
    >
        <Modal.Body >
        <div className="welcome-modal__wrapper">
            <div><img src={welcome} alt='thank you cover'/></div>
            <div className="title mt-4">
                Welcome to your desktop view, {currentCourseStore?.generalConfig?.patientName}
            </div>
            <div className="description">
                <span className="pb-4">Your activity here will automatically be reflected in your OneStep app.</span>
                <span>We hope you enjoy your viewing experience on a larger screen.</span>
            </div>
            <div className="mt-4">
				<button className="btn btn-primary" onClick={() => {
                    setShow(false)
                    cookies.set('Show-welcome-dialog', false)
                }}>
                    Ok
				</button>
			</div>
        </div>
        </Modal.Body>

    </Modal>
  );
};

export default observer(WelcomeModal);
