
import React, { useEffect, useState } from "react";
import { Info } from "react-bootstrap-icons";
import Timer from "../../../components/CountDown";
import PlayAudio from "../../../components/PlayAudio";
import InstructionModal from "../../../modals/instructionModal";
import ImportantTipsModal from "../../../modals/importantTipsModal";


const AutoPrepareView = ({...props}) => {
    const {currentSession, currentTaskIndex, currentTask, PrepareTimerDone, setPlaySound} = props
	const [openInstructionModal, setOpenInstructionModal] = useState(false);
	const [pauseTimer, setPauseTimer] = useState(false);
	const [preparePeriod, setPreparePeriod] = useState(currentTask?.generalInfo.prepTime);
	const hasHints = !!currentTask?.hints?.length
	const [openHintsModal, setOpenHintsModal] = useState(false)

	const handlePrepareDoneAction = () => {
		setPreparePeriod(0)
		if(hasHints) {
			setOpenHintsModal(true)
			setPauseTimer(true)
		} else {
			PrepareTimerDone()
		}
	}

	useEffect(() => {
		setPauseTimer(openInstructionModal)
	  }, [openInstructionModal, setPauseTimer]);

	  useEffect(() => {
        let tipTimeOut
		if(hasHints && openHintsModal) {
			tipTimeOut  = setTimeout(() => {
				setOpenHintsModal(false)
                PrepareTimerDone()
				console.log('here')
			}, 5500)
		} else {
            clearTimeout(tipTimeOut)
        }

		return () => {
            clearTimeout(tipTimeOut);
        };
	  }, [PrepareTimerDone, hasHints, openHintsModal]);

	return (
		<div className="task-prepare-view__wrapper auto-prepare-view">
			<InstructionModal openModal={openInstructionModal} setOpenModal={setOpenInstructionModal} currentTask={currentTask} hideNavArrow={true}  />

			{currentTask && <PlayAudio src={currentTask?.generalInfo.prepAudioUrl} pauseAduio={pauseTimer} />}
			<div className="view-header text-center">
				<div className="related-session">Day{currentSession.sessionIndex+1}</div>
				<div className="task-position">Exercise {currentTaskIndex} of {currentSession && currentSession.tasks?.length}</div>
			</div>
			{currentTask.image?.url &&
				<div className="current-task-image">
					<div className="show-instruction" onClick={() => {
							setOpenInstructionModal(true)
							setPauseTimer(true)
						}}>
							<Info size={24} />
						</div>
					<img src={currentTask.image.url} alt={currentTask.image?.title ? currentTask.image.title: currentTask.title} />
				</div>
			}
			{currentTask.title &&
				<div className="task-name">
					<div>Prepare for:</div>
					<div className="task-name__value">{currentTask.title}</div>
				</div>
			}
			<div className="countdown">
				<Timer period={preparePeriod} timerDone={() =>handlePrepareDoneAction()} setPlaySound={setPlaySound} playSound={true} pauseTimer={pauseTimer} />
			</div>
			<div className="task-start-btn__wrapper sticky-bottom glow-effect">
				<button className="btn btn-primary" onClick={() => handlePrepareDoneAction()}>
					Begin Now
				</button>
			</div>

			{hasHints &&
                <ImportantTipsModal
                    openHintsModal={openHintsModal}
                    setOpenHintsModal={setOpenHintsModal}
                    PrepareTimerDone={PrepareTimerDone}
                    hints={currentTask?.hints} />}
		</div>
	);
};

export default AutoPrepareView;