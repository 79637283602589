
import React, {useState} from "react";
import {loginApi} from '../../api/login'
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import OtpInput from "react-otp-input";

import {currentCourseStore} from '../../stores/currentCourseStore';
import Logo from '../../assets/images/Logo.png'




import { observer } from 'mobx-react-lite';
import NeedHelpModal from "../../modals/needHelpModal";

const cookies = new Cookies();

const LogInPage = () => {
    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState('');
    const [otpValue, setOtpValue] = useState('')
    const [openHelpModal, setpenHelpModal] = useState(false)

    const formValidation = () => {
        if (otpValue.length === 6) {
            return true
        } else {
                setErrorMessage('Please fill the 6 digits code')
            return false
        }
    }

    const handleSubmit = (e) => {
        const isValidForm = formValidation()
        if (isValidForm) {
            loginApi(otpValue).then((data) => {
                if (data && data.hasError) {
                    setErrorMessage('Please Enter Valid code')
                } else {
                    const {currentCourseData, patientInfo, therapist} = data
                    currentCourseStore.updateGeneralConfig({
                        patientName: patientInfo.firstName,
                        currentCourseUuid: currentCourseData.uuid,
                        therapist
                    })
                    cookies.set('patient-name', patientInfo.firstName)
                    cookies.set('course-uuid', currentCourseData.uuid)
                    cookies.set('Show-welcome-dialog', true)
                    cookies.set('Show-popup', true)
                    cookies.set('show-general-feedback', true)
                    cookies.set('finished-session', false)
                    navigate('/courses')
                }
            })
        }
    };

  return (
    <div className="login-page__wrapper">
        <NeedHelpModal openModal={openHelpModal} setOpenModal={setpenHelpModal}/>
        <div className="logo">
            <img
                src={Logo}
                alt="SoundBe logo"
            ></img>
        </div>
        <div className="welcom-msg">
            Welcome to your desktop view
        </div>
        <div className="description">
            To watch your exercise session on your computer, enter the code you recieved within the app.
        </div>
        <div className="form">
            <form onSubmit={(e) => {
                e.preventDefault()
                handleSubmit();
            }}>
                <OtpInput
                    value={otpValue}
                    onChange={(otp) => {
                        setOtpValue(otp)
                    }}
                    numInputs={6}
                    isInputNum={true}
                    containerStyle='otp-input-conainer'
                    inputStyle='otp-input'
                />
                {errorMessage &&
                    <span className="error d-block">{errorMessage}</span>
                }
                <div className="help-link" onClick={() => setpenHelpModal(true)}>
                    Need help? See intsurctions
                </div>
                <div className="sticky-bottom glow-effect">
                    <button className="btn btn-primary" type="Submit" disabled={otpValue.length < 6}>Submit</button>
                </div>
            </form>
        </div>
    </div>
  );
};

export default observer(LogInPage);