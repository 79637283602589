
import React, {useState} from "react";
import { observer } from 'mobx-react-lite';

import {currentCourseStore} from '../../stores/currentCourseStore';
import SessionFeedback from "../../components/Feedback/sessionFeedback";

import TaskFeedback from "../../components/Feedback/taskFeedback";



const FeedbackPage = () => {

    const [continueToTaskFeedback, setContinueToTaskFeedback] = useState(false)

    const doneSessionInfo = currentCourseStore?.doneSessionInfo
    const tasks = currentCourseStore.allTasks
    const therapistProfileImage = currentCourseStore?.generalConfig?.therapist?.profileImageUrl
    const therapistName = currentCourseStore?.generalConfig?.therapist?.firstName

    return (
        <div>
            {!continueToTaskFeedback ?
                <SessionFeedback therapistProfileImage={therapistProfileImage} sessionUuid={doneSessionInfo.uuid} setContinueToTaskFeedback={setContinueToTaskFeedback} therapistName={therapistName} />

            :
                <TaskFeedback therapistProfileImage={therapistProfileImage} tasks={tasks} />
            }
        </div>

    );
};

export default observer(FeedbackPage);