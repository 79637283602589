import React from "react";

const taskChips = [
	{
		value:'Too hard',
		key: 'Too hard',
	},
	{
		value:'Hard',
		key: 'Hard',
	},
	{
		value:'Just right',
		key: 'Just right',
	},
	{
		value:'Easy',
		key: 'Easy',
	},
	{
		value:'Too easy',
		key: 'Too easy',
	}
]




const sessionChips = [
	{
		value:'Too long',
		key: 'Too long',
	},
	{
		value:'Too short',
		key: 'Too short',
	},
	{
		value:'Monotonic',
		key: 'Monotonic',
	},
	{
		value:'Just right',
		key: 'Just right',
	},
	{
		value:'Not challenging  enough',
		key: 'Not challenging  enough',
	},
	{
		value:'Wrong exercise order',
		key: 'Wrong exercise order',
	},
	{
		value:'Too challenging',
		key: 'Too challenging',
	},
	{
		value:'Perfect length',
		key: 'Perfect length',
	},
	{
		value:'Good variety',
		key: 'Good variety',
	},
	{
		value:'Not enough time between exercises',
		key: 'Not enough time between exercises',
	},
	{
		value:'Too much time between exercises',
		key: 'Too much time between exercises',
	}
]

const ChipComponent = (props) => {
	const {updateSelectionsArray, selectionsArray, isSingleSelection = false} = props
	const chips = isSingleSelection ? taskChips : sessionChips

	return(
		<div className="feedback-chips__wrapper">
			<div className="feedback-chips">
				{chips && chips.map((chip) => {
					return (
						<div className={`chip ${selectionsArray.includes(chip.value) ? 'selected' : ''}`} key={chip.key} onClick={()=> {
							if(selectionsArray.includes(chip.value)) {
								updateSelectionsArray(selectionsArray.filter(item => item !== chip.value))
							} else {
								if(isSingleSelection) {
									updateSelectionsArray([chip.value])
								} else {
									updateSelectionsArray([...selectionsArray, chip.value])
								}
							}
						}}>
							{chip.value}
						</div>
					)
				})}
			</div>
		</div>
	)
}



export default ChipComponent
