
import React, {useState} from "react";
import { observer } from 'mobx-react-lite';
import Modal from 'react-bootstrap/Modal';
import { Mixpanel } from '../config/mixpanel';
import Cookies from 'universal-cookie';


const cookies = new Cookies();

const GeneralFeedbackModal = () => {

    const [show, setShow] = useState(true);
    const [textAreaValue, SetTextAreaValue] = useState('')

    return (
    <Modal
        show={show}
        onHide={() => {
            setShow(false)
            cookies.set('show-general-feedback', false)
            cookies.set('finished-session', false)
        }}
        size='md'
        centered
    >
        <Modal.Header closeButton />
        <Modal.Body >
        <div className="general-feedback__wrapper">
            <div className="title">
                We’d love to hear from you
            </div>
            <div className="description">
                This desktop view feature is in Beta version.
                This means we’re open to suggestions on how to make it better for you.
                Let us know if you have any  comments or suggesions on how we can improve.
            </div>
            <div>
                <textarea
                    value={textAreaValue}
                    onChange={(e) => {
						SetTextAreaValue(e.target.value)
					}}
                    rows={5}
                />
            </div>
            <div className="mt-4 px-4">
				<button className="btn btn-primary" onClick={() => {
                    //send feedback and go to courses page
                    setShow(false)
                    Mixpanel.track('patient_web_feedback', {feedback: textAreaValue});
                    cookies.set('show-general-feedback', false)
                    cookies.set('finished-session', false)

                }}>
                    Submit
				</button>
			</div>
        </div>
        </Modal.Body>

    </Modal>
  );
};

export default observer(GeneralFeedbackModal);
