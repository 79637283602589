
import React, {useEffect, useState} from "react";
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';
import Slider from "react-slick";

import {currentCourseStore} from '../../stores/currentCourseStore';
import { useNavigate } from 'react-router-dom';
import Switch from "react-switch";

import { ArrowRightCircleFill, ArrowLeftCircleFill, PlayCircleFill } from 'react-bootstrap-icons';
import InstructionModal from '../../modals/instructionModal'
import VideoModal from "../../modals/videoModal";
import InfoPopup from "../../components/InfoPopup";
import Cookies from 'universal-cookie';




const getRelatedSessionData = (sessionInfo, allTasks, allEquipments) => {
    const sessionEquipmentIds = []
	const sessionRelatedEquipments = []
    const sessionRelatedTask = []
    // eslint-disable-next-line array-callback-return
    sessionInfo && sessionInfo.tasks && sessionInfo.tasks.map((task) => {
        // eslint-disable-next-line array-callback-return
        allTasks && allTasks.map((taskData) => {
            if (task.baseTaskUuid === taskData.uuid) {
                sessionRelatedTask.push({...taskData, generalInfo: {...task}})
            }
        })
    })
    // eslint-disable-next-line array-callback-return
    sessionRelatedTask && sessionRelatedTask.map((task) => {
        // eslint-disable-next-line array-callback-return
        task.equipmentUuids && task.equipmentUuids.map((equipmentUuid) => {
            if (!(sessionEquipmentIds.includes(equipmentUuid))) {
                sessionEquipmentIds.push(equipmentUuid)
            }
        })
    })
    // eslint-disable-next-line array-callback-return
    sessionEquipmentIds && sessionEquipmentIds.map((equipmentId) => {
        // eslint-disable-next-line array-callback-return
        allEquipments && allEquipments.map((equipment) => {
            if (equipmentId === equipment.uuid) {
                sessionRelatedEquipments.push({...equipment})
            }
        })
    })
    return {sessionRelatedEquipments, sessionRelatedTask}
}


const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={classnames(className, 'custom-arrow')}
        onClick={onClick}
      >
        <ArrowRightCircleFill color="#B4DCBE" size={45} />
        </div>
    );
  }

const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={classnames(className, 'custom-arrow')}
        onClick={onClick}
      >
        <ArrowLeftCircleFill color="#B4DCBE" size={45} />
        </div>
    );
  }

const cookies = new Cookies();

const SessionDetails = () => {

    const navigate = useNavigate();
    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 6.5,
        slidesToScroll: 1,
        swipe: false,
        useCSS: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
      };

    const [theEquipments, setEquipments] = useState([])
    const [tasks, setTasks] = useState([])
    const [openInstructionModal, setOpenInstructionModal] = useState(false);
    const [openVideoModal, setOpenVideoModal] = useState(false);
    const [showInfoPopup, setShowInfoPopup] = useState(cookies.get('Show-popup')  === 'true');

    const [currentTaskIdx, setCurrentTaskIdx] = useState(-1);
    const [checked, setChecked] = useState(currentCourseStore.isAutoplayMood);

    const sessionInfo = currentCourseStore?.currentSession
    const allEquipment = currentCourseStore?.baseTase.allEquipments
    const allTask = currentCourseStore?.baseTase.allTasks
    const currentCourseInfo = currentCourseStore?.currentCourseStoreData

    const therapistProfileImage = currentCourseStore?.generalConfig?.therapist?.profileImageUrl

    const handleStartWorkout = () => {
        const currentTask = tasks.find((task) => task.generalInfo.status === 0)
        const currentTaskIndex = tasks.findIndex((task) => task.generalInfo.status === 0)
        currentCourseStore.updateCurrentTask(currentTask)
        currentCourseStore.updateCurrentTaskIndex(currentTaskIndex+1)
        currentCourseStore.updateShowPrepareMode(true)
        navigate(`/task`)

    }

    useEffect(() => {
        const {sessionRelatedEquipments, sessionRelatedTask} = getRelatedSessionData(sessionInfo, allTask, allEquipment)
        setEquipments(sessionRelatedEquipments)
        setTasks(sessionRelatedTask)
        currentCourseStore.updateSessionTasks(sessionRelatedTask)

    }, [allEquipment, allTask, navigate, sessionInfo]);

    useEffect(() => {
        setShowInfoPopup(cookies.get('Show-popup')  === 'true')

    }, [setShowInfoPopup]);


    const handleReplay = (session) => {
        const currentTask = session
        // const currentTaskIndex = tasks.findIndex((task) => task.generalInfo.status === 0)
        currentCourseStore.updateCurrentTask(currentTask)
        currentCourseStore.updateShowReplayview(true)
        // currentCourseStore.updateCurrentTaskIndex(currentTaskIndex+1)
        navigate(`/task`)
    }


    const handleAutoplay = () => {
        currentCourseStore.updateAutoplay(!checked)
        setChecked(!checked)
    }

    const handleShowTaskDetails = (idx) => {
        setCurrentTaskIdx(idx)
        setOpenInstructionModal(true)
    }



  return (
    sessionInfo &&
    <div className="session-page__wrapper">
        {showInfoPopup  && <div className='mask'></div>}
        <VideoModal openModal={openVideoModal} setOpenModal={setOpenVideoModal} videoSrc={sessionInfo.personalizedVideo?.url} />
        <div className="session-page__header">
            <div className="d-flex">
                <div className="couese-name">{currentCourseInfo.title}</div>
                <div className="session-name">Day {currentCourseInfo.currentSession + 1}</div>
            </div>
            <div className="auto-play-switch d-flex">
                <span>Autoplay</span>
                <div className="switch_wrapper">
                    {showInfoPopup && <InfoPopup setShow={setShowInfoPopup} />}
                    <Switch
                        onHandleColor="#C9C9C9"
                        offHandleColor="#9A9A9A"
                        onColor="#23A377"
                        offColor="#C9C9C9"
                        uncheckedIcon={false}
                        checkedIcon={false}
                        onChange={handleAutoplay}
                        checked={checked}
                        handleDiameter={20}
                        className="auto-switch"
                    />
                </div>
            </div>
        </div>
        {sessionInfo?.description &&
            <div className="session-description">
                {sessionInfo.description}
            </div>
        }
        {sessionInfo.personalMsg && 
            <div className="personal-msg__wrapper">
                <div className="section-title">Personal message From Hadas</div>
                <div>{sessionInfo.personalMsg}</div>
            </div>
        }
        {sessionInfo.personalizedVideo?.url &&
            <div className="personal-video__wrapper mt-3 mb-3">
                <div className="section-title mb-2">Personal video from Hadas</div>
                <button className="icon-btn" onClick={() => setOpenVideoModal(true)} >
                    <img
                        alt='personal video' 
                        src={sessionInfo.personalizedVideo.thumbnailUrl? sessionInfo.personalizedVideo.thumbnailUrl : sessionInfo.personalizedVideo.videoCoverImage }
                    />
                    <PlayCircleFill color="#000000" size={25}/>
                </button>
            </div>
        }
        {theEquipments && theEquipments.length > 0 &&
            <div className="equipments row">
                {/* eslint-disable-next-line array-callback-return */}
                <div className="section-title mt-2 mb-2 col-12">Required equipment</div>
                <div className="col-12 d-flex">
                    {theEquipments && theEquipments.map((equipment, idx) =>
                        <div className="equipment__wrapper" key={idx}>
                            <div className="equipment-img"><img src={equipment.image.url} alt="" /></div>
                            <div className="equipment-name"> {equipment.title}</div>
                        </div>
                    )}
                </div>
            </div>
        }

        <div className="session-tasks__wrapper row">
            <div className="section-title">Your Exercises</div>

            <Slider {...settings}>
            {tasks && tasks.map((session, idx) => {
                return (
                    <div className="session-task__section col-12" key={idx}>
                        <button className={classnames({'task-button': true})} onClick={() => handleShowTaskDetails(idx)}>
                            <div className="task-section__wrapper">
                                <div className={classnames({
                                    'task-image': true,
                                    'done': session.generalInfo.status === 1
                                })}>
                                    {!!session?.generalInfo?.personalMsg &&
                                        <div className="personal-msg__wrapper"> 
                                            <div className="avatar">
                                                <svg width="14" height="14" viewBox="0 0 14 14" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M3.53567 13.0021C3.37388 12.9248 3.29299 12.8088 3.29299 12.6542V10.3353H1.27067C1.028 10.3353 0.866211 10.1807 0.866211 9.9488V1.23243C0.866211 1.00054 1.028 0.845947 1.27067 0.845947H12.5956C12.8383 0.845947 13.0001 1.00054 13.0001 1.23243V9.9488C13.0001 10.1807 12.8383 10.3353 12.5956 10.3353H7.09494L3.98064 12.9634C3.89975 13.0021 3.77841 13.0407 3.69752 13.0407C3.657 13.0407 3.57611 13.0407 3.53567 13.0021Z" fill="white" stroke="#343434"/>
                                                </svg>
                                                {therapistProfileImage && <img src={therapistProfileImage} alt='therapist Profile' />}
                                            </div>
                                        </div>
                                    }
                                    <img src={session.image.url} alt={session.title} />
                                </div>
                                <div className="task-details">
                                    <div className="task-description">{session.title}</div>
                                    <div className="task-duration">{session.seconds} sec</div>
                                </div>
                            </div>
                        </button>
                        {session.generalInfo.status === 1 &&
                            <div className="replay-btn__wrapper">
                                <div>Done</div>
                                <button className="btn btn-primary" onClick={() => handleReplay(session)}>Replay</button>
                            </div>
                        }
                    </div>
                )
            })
            }
            </Slider>
        </div>
        {tasks?.length && tasks[tasks.length -1].status !== 1 &&
            <div className="start-btn_wrapper glow-effect sticky-bottom row">
                <button className="btn btn-primary" onClick={() => handleStartWorkout()}>
                    {tasks[0].generalInfo.status === 0 ? 'Start workout' : 'Resume workout'}
                </button>
            </div>
        }
        <InstructionModal openModal={openInstructionModal} setOpenModal={setOpenInstructionModal} currentnidx={currentTaskIdx}  />
        {/* <Modal
            show={show}
            onHide={handleClose}
            size='lg'
            centered
        >
            <Modal.Body >
                <VoiceCommands handleStartWorkout={handleStartWorkout}  />
            </Modal.Body>
        </Modal> */}
    </div>
  );
};

export default observer(SessionDetails);