
import React, {useEffect, useState} from "react";
import { observer } from 'mobx-react-lite';
import Modal from 'react-bootstrap/Modal';

const NeedHelpModal = (props) => {
    const {openModal, setOpenModal} = props

    const [show, setShow] = useState(openModal);


    useEffect(() => {
        setShow(openModal)

    }, [openModal]);

  return (
    <Modal
        show={show}
        onHide={() => {
            setShow(false)
            setOpenModal(false)
        }}
        size='md'
        centered
    >
        <Modal.Body >
        <div className="need-help-modal__wrapper">
            <div className="title">
                How to watch your exercise session on your computer
            </div>
            <ol type="1" className="description">
                <li>Open the OneStep app on your phone</li>
                <li>Open your exercise plan</li>
                <li>Click “Start workout”</li>
                <li>Click the “Watch on desktop” button</li>
                <li>Enter your access code in this screen</li>
            </ol>
            <div className="mt-4">
				<button className="btn btn-primary" onClick={() => {
                    setShow(false)
                    setOpenModal(false)
                }}>
                    ok
				</button>
			</div>
        </div>
        </Modal.Body>

    </Modal>
  );
};

export default observer(NeedHelpModal);
