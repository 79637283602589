
import React from "react";
const PauseSvg = () => {
  return (
    <div className="pause-svg">
        <svg width="60" height="60" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="32.1017" cy="31.6452" r="31.6452" fill="#23A377"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M22.2825 18C21.8276 18.0021 21.4586 18.371 21.4566 18.826V43.6989C21.4545 44.157 21.8245 44.5291 22.2825 44.5311H29.7423C29.9641 44.5322 30.1755 44.4441 30.332 44.2886C30.4885 44.1321 30.5755 43.9197 30.5745 43.6989V18.826C30.5735 18.3679 30.2004 17.9979 29.7423 18L22.2825 18ZM34.8227 18C34.3646 17.998 33.9926 18.3679 33.9905 18.826V43.6989C33.9895 43.9197 34.0765 44.1321 34.233 44.2886C34.3895 44.4441 34.602 44.5322 34.8227 44.5311H42.2825C42.5043 44.5322 42.7167 44.4451 42.8732 44.2886C43.0297 44.1321 43.1178 43.9197 43.1168 43.6989V18.826C43.1157 18.6053 43.0276 18.3949 42.8712 18.2405C42.7147 18.085 42.5033 17.999 42.2825 18L34.8227 18Z" fill="white"/>
        </svg>
    </div>
  );
};

export default PauseSvg;