
import React, {useState, useEffect} from "react";
import Modal from 'react-bootstrap/Modal';

import Timer from "../../../components/CountDown";
import PlayAudio from "../../../components/PlayAudio";
import PlayVideo from "../../../components/PlayVideo";
import { PlayCircleFill, PauseCircleFill, ArrowCounterclockwise, Info } from 'react-bootstrap-icons';
import { observer } from "mobx-react-lite";
import { currentCourseStore } from "../../../stores/currentCourseStore";
import InstructionModal from "../../../modals/instructionModal";


const RePlayTaskView = (props) => {
	const {currentTask, setRepeatTask, repeatTask, pauseTask, taskTimerDone, setPauseTask, setPlaySound} = props

	const hasPersonalMsg = !!currentTask?.generalInfo?.personalMsg
	const [openPersonalMsgModal, setOpenPersonalMsgModal] = useState(hasPersonalMsg)
	const therapistProfileImage = currentCourseStore?.generalConfig?.therapist?.profileImageUrl
	const [openInstructionModal, setOpenInstructionModal] = useState(false);

	useEffect(() => {
		if(hasPersonalMsg && openPersonalMsgModal) {
			setPauseTask(true)
			setTimeout(() => {
				setOpenPersonalMsgModal(false)
				setPauseTask(false)
			}, 10000)
		} else if (!hasPersonalMsg && openPersonalMsgModal) {
			setOpenPersonalMsgModal(false)
			setPauseTask(false)
		}
	}, [hasPersonalMsg, openPersonalMsgModal, setPauseTask]);

	useEffect(() => {
		setPauseTask(openInstructionModal || openPersonalMsgModal)
	}, [openInstructionModal, openPersonalMsgModal, setPauseTask]);

	const timerDone = () => {
		taskTimerDone()
		currentCourseStore.updateShowTransitionMode(true)
	}

	return (
		<div className="task-view-page replay-task-view">
			<div className="task-play-view__wrapper">
				<InstructionModal openModal={openInstructionModal} setOpenModal={setOpenInstructionModal} currentTask={currentTask} hideNavArrow={true}  />
				{currentTask && currentTask.audio?.url && <PlayAudio repeatDone={() => {setRepeatTask(false)}} repeat={repeatTask} src={currentTask.audio.url} pauseAduio={pauseTask} />}
				{currentTask.video?.url ?
					<div className="current-task-image">
						<div className="footer-wrapper glow-effect">

							{currentTask?.seconds &&
								<div className="countdown">
									<Timer period={currentTask.seconds} repeatDone={() => {setRepeatTask(false)}} repeat={repeatTask} timerDone={timerDone} pauseTimer={pauseTask} setPlaySound={setPlaySound} playSound={true} />
								</div>
							}

							<div className="task-component__wrapper">
								<div className="control-buttons__wrapper">
									<div className="repeat-button">
										<button className="icon-btn">
											<ArrowCounterclockwise color="#23A377" size={24} onClick={() => {
												setRepeatTask(true)}} />
										</button>
									</div>
									{!pauseTask &&
										<button className="icon-btn" onClick={() => {setPauseTask(true)}}>
											<PauseCircleFill color="#23A377" size={48}/>
										</button>
									}
									{pauseTask &&
										<button className="icon-btn" onClick={() => {setPauseTask(false)}}>
											<PlayCircleFill color="#23A377" size={48}/>
										</button>
									}
								</div>
							</div>
						</div>
						<div className="show-instruction" onClick={() => {
							setOpenInstructionModal(true)
							setPauseTask(true)
						}}>
							<Info size={24} />Instructions
						</div>
						<PlayVideo repeatDone={() => {setRepeatTask(false)}} repeat={repeatTask} src={currentTask.video.url} pauseVideo={pauseTask} />
					</div>
				:
					<div className="current-task-image">
						<div className="footer-wrapper glow-effect">
							{currentTask?.seconds &&
								<div className="countdown">
									<Timer period={currentTask.seconds} repeatDone={() => {setRepeatTask(false)}} repeat={repeatTask} timerDone={timerDone} pauseTimer={pauseTask} setPlaySound={setPlaySound} playSound={true} />
								</div>
							}

							<div className="task-component__wrapper">
								<div className="control-buttons__wrapper">
									<div className="repeat-button">
										<button className="icon-btn">
											<ArrowCounterclockwise color="#23A377" size={24} onClick={() => {
												setRepeatTask(true)}} />
										</button>
									</div>
									{!pauseTask &&
										<button className="icon-btn" onClick={() => {setPauseTask(true)}}>
											<PauseCircleFill color="#23A377" size={48}/>
										</button>
									}
									{pauseTask &&
										<button className="icon-btn" onClick={() => {setPauseTask(false)}}>
											<PlayCircleFill color="#23A377" size={48}/>
										</button>
									}
								</div>
							</div>
						</div>
						<div className="show-instruction" onClick={() => {
							setOpenInstructionModal(true)
							setPauseTask(true)
						}}>
							<Info size={24} />Instructions
						</div>
						<img src={currentTask.image.url} alt={currentTask.image?.title ? currentTask.image.title: currentTask.title} />
					</div>
				}
			</div>
			<Modal
        		show={openPersonalMsgModal}
				onHide={() => {}}
				size='md'
				centered
			>
				<Modal.Body >
					<div className="personal-msg__modal">
						<div className="personal-msg__wrapper">
							<div className="avatar">
							<svg width="24" height="24" viewBox="0 0 14 14" fill="#fff" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" clipRule="evenodd" d="M3.53567 13.0021C3.37388 12.9248 3.29299 12.8088 3.29299 12.6542V10.3353H1.27067C1.028 10.3353 0.866211 10.1807 0.866211 9.9488V1.23243C0.866211 1.00054 1.028 0.845947 1.27067 0.845947H12.5956C12.8383 0.845947 13.0001 1.00054 13.0001 1.23243V9.9488C13.0001 10.1807 12.8383 10.3353 12.5956 10.3353H7.09494L3.98064 12.9634C3.89975 13.0021 3.77841 13.0407 3.69752 13.0407C3.657 13.0407 3.57611 13.0407 3.53567 13.0021Z" fill="white" stroke="#343434"/>
							</svg>
								{therapistProfileImage && <img src={therapistProfileImage} alt='therapist Profile' />}
							</div>
							<div className="personal-msg_content">
								{currentTask.generalInfo.personalMsg}
							</div>
						</div>
						<div>
							<button className="btn btn-primary" onClick={() => {
								setOpenPersonalMsgModal(false)
								setPauseTask(false)
							}} >
								Got it!
							</button>
						</div>
					</div>
				</Modal.Body>

			</Modal>
		</div>
	);
};

export default observer(RePlayTaskView);