
import React from "react";
const PlaySvg = () => {
  return (
    <div className="play-svg">
        <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="28.5" cy="28" r="28" fill="#23A377"/>
          <rect width="18.1622" height="18.1622" transform="translate(20.1758 18.9189)" fill="#23A377"/>
          <mask id="mask0_6890_256" maskUnits="userSpaceOnUse" x="20" y="18" width="19" height="20">
          <path fillRule="evenodd" clipRule="evenodd" d="M20.1758 18.9189H38.3379V37.0811H20.1758V18.9189Z" fill="white"/>
          </mask>
          <g mask="url(#mask0_6890_256)">
          <path fillRule="evenodd" clipRule="evenodd" d="M36.0558 26.857C37.0805 27.4857 37.0805 28.5144 36.0558 29.1431L31.6675 31.835C30.6427 32.4636 28.9656 33.4923 27.9409 34.1209L23.5526 36.8128C22.5278 37.4415 21.6892 36.9271 21.6892 35.6699V30.2861V25.714V20.3303C21.6892 19.0729 22.5278 18.5586 23.5526 19.1873L27.9409 21.8791C28.9656 22.5078 30.6427 23.5365 31.6675 24.1652L36.0558 26.857Z" fill="#FEFEFE"/>
          </g>
        </svg>

    </div>
  );
};

export default PlaySvg;