
import React, {useState} from "react";
import { useNavigate } from 'react-router-dom';
import { currentCourseStore } from '../../stores/currentCourseStore';
import { observer } from "mobx-react-lite";
import AutoPrepareView from "./partials/autoPrepareView";
import PlayTaskView from "./partials/playTaskView";
import RePlayTaskView from "./partials/rePlayTaskView";
import EndExerciseSound from "../../components/EndExerciseSound";


const AutoPlayModeTask = () => {
	const navigate = useNavigate();

	const [pauseTask, setPauseTask] = useState(false)
	const [repeatTask, setRepeatTask] = useState(false)
	const [playSound, setPlaySound] = useState(false);

	// const [openDoneModal, setOpenDoneModal] = useState(false)

	const currentTask = currentCourseStore?.currentTask
	const currentTaskIndex = currentCourseStore?.currentTaskIndex
	const currentSession = currentCourseStore?.currentSession
	const allTasks = currentCourseStore.allTasks
	const showReplayview = currentCourseStore.showReplayview


	const PrepareTimerDone = () => {
		// set ShowPrepareView false
		currentCourseStore.updateShowPlayMode(true)
	}


	const taskTimerDone = () => {
		if (showReplayview) {
			currentCourseStore.updateShowReplayview(false)
			navigate('/session')
		} else {
			// go to the next task
			if(currentTaskIndex === allTasks.length) {
				//update status for the session using api/v4/sessions/{sessionUUID}
				currentCourseStore.setCurrentTaskStatus(currentTask.generalInfo.uuid)
				.then((data) => {
					if(data.hasError) {
						navigate('/login')
					}
				})
				currentCourseStore.setDoneSessionInfo({
					uuid: currentSession.uuid,
					sessionIndex: currentSession.sessionIndex
				})
				const isLastsession = currentSession.sessionIndex === currentCourseStore?.currentCourseStoreData?.sessionsCount
				currentCourseStore.setCurrentSessionStatus(currentSession.uuid, 2, isLastsession)
				.then((data) => {
					if(data.hasError) {
						navigate('/login')
					}
				})
				currentCourseStore.updateShowPrepareMode(true)
				navigate('/done')
			} else {
				currentCourseStore.setCurrentTaskStatus(currentTask.generalInfo.uuid)
				.then((data) => {
					if(data.hasError) {
						navigate('/login')
					}
				})
				currentCourseStore.updateCurrentTask(allTasks[currentTaskIndex])
				// setShowPrepareView(true)
				currentCourseStore.updateShowPrepareMode(true)
				currentCourseStore.updateCurrentTaskIndex(currentTaskIndex+1)
				// ToDo update status for the task using api/v4/tasks/{taskUUID}
			}
		}
	}
	const goBack = () => {
		if(currentTaskIndex === 1) {
			//update status for the session using api/v4/sessions/{sessionUUID}
			navigate('/session')
		} else {
			currentCourseStore.updateCurrentTask(allTasks[currentTaskIndex-2])
			currentCourseStore.updateShowPrepareMode(true)
			currentCourseStore.updateCurrentTaskIndex(currentTaskIndex-1)
			// ToDo update status for the task using api/v4/tasks/{taskUUID}
		}
	}


	return (
		<div className="task-view-page">
		<div className="">
			<EndExerciseSound playSound={playSound} setPlaySound={setPlaySound} />
			{currentCourseStore.showReplayview &&
				<RePlayTaskView currentTask={currentTask} setRepeatTask={setRepeatTask} repeatTask={repeatTask} pauseTask={pauseTask} taskTimerDone={taskTimerDone} goBack={goBack} setPauseTask={setPauseTask} setPlaySound={setPlaySound} />
			}
			{!showReplayview && currentCourseStore.showPrepareMode &&
			// show normal flow
				<AutoPrepareView currentSession={currentSession} currentTaskIndex={currentTaskIndex} currentTask={currentTask} PrepareTimerDone={PrepareTimerDone} setPlaySound={setPlaySound} />
			}
			{!showReplayview && currentCourseStore.showPlayMode && // show play
				<PlayTaskView currentTask={currentTask} setRepeatTask={setRepeatTask} repeatTask={repeatTask} pauseTask={pauseTask} taskTimerDone={taskTimerDone} goBack={goBack} setPauseTask={setPauseTask} />
			}
		</div>
		</div>
		

	);
};

export default observer(AutoPlayModeTask);