
import React, {useState, useEffect} from "react";
import Modal from 'react-bootstrap/Modal';

import Timer from "../../../components/CountDown";
import PlayAudio from "../../../components/PlayAudio";
import PlayVideo from "../../../components/PlayVideo";
import { Info } from 'react-bootstrap-icons';
import ChevronSvg from "../../../components/Svg/Chevron"
import PlaySvg from "../../../components/Svg/Play"
import ReplaySvg from "../../../components/Svg/Replay"
import PauseSvg from "../../../components/Svg/Pause"

import { observer } from "mobx-react-lite";
import { currentCourseStore } from "../../../stores/currentCourseStore";
import InstructionModal from "../../../modals/instructionModal";
import Instructions from "./instructions";

const PlayTaskView = (props) => {
	const {currentTask, setRepeatTask, repeatTask, pauseTask, taskTimerDone, goBack, setPauseTask, setPlaySound} = props

	const hasPersonalMsg = !!currentTask?.generalInfo?.personalMsg
	const [openPersonalMsgModal, setOpenPersonalMsgModal] = useState(hasPersonalMsg)
	const therapistProfileImage = currentCourseStore?.generalConfig?.therapist?.profileImageUrl
	const [openInstructionModal, setOpenInstructionModal] = useState(false);
	const exercisePause = currentCourseStore.exercisePause
	// const currentTaskIndex = currentCourseStore?.currentTaskIndex



	useEffect(() => {
		if(hasPersonalMsg && openPersonalMsgModal) {
			// setOpenPersonalMsgModal(true)
			setPauseTask(true)
			// close()
			setTimeout(() => {
				setOpenPersonalMsgModal(false)
				setPauseTask(false)
			}, 10000)
		} else if (!hasPersonalMsg && openPersonalMsgModal) {
			setOpenPersonalMsgModal(false)
			setPauseTask(false)
		}
	  }, [hasPersonalMsg, openPersonalMsgModal, setPauseTask]);

	  useEffect(() => {
		setPauseTask(openInstructionModal || openPersonalMsgModal || exercisePause)
	  }, [exercisePause, openInstructionModal, openPersonalMsgModal, setPauseTask]);

	const timerDone = () => {
		taskTimerDone()
		if(currentCourseStore.isAutoplayMood) {
			currentCourseStore.updateShowPrepareMode(true)
		} else {
			currentCourseStore.updateShowTransitionMode(true)
		}
		
	}

	return (
		<div className="play-task-view">
			<div className="task-play-view__wrapper">
				<InstructionModal openModal={openInstructionModal} setOpenModal={setOpenInstructionModal} currentTask={currentTask} hideNavArrow={true}  />
				{currentTask && currentTask.audio?.url && <PlayAudio repeatDone={() => {setRepeatTask(false)}} repeat={repeatTask} src={currentTask.audio.url} pauseAduio={pauseTask} />}
				<div className="content">
					<div>
						{currentTask.video?.url ?
							<div className="current-task-image">
								<div className="footer-wrapper glow-effect">

									{currentTask?.seconds &&
										<div className="countdown">
											<Timer period={currentTask.seconds} repeatDone={() => {setRepeatTask(false)}} repeat={repeatTask} timerDone={timerDone} pauseTimer={pauseTask} setPlaySound={setPlaySound} playSound={!currentCourseStore.isAutoplayMood} />
										</div>
									}

									<div className="task-component__wrapper">
										<div className="control-buttons__wrapper">
											<div className="repeat-button">
												<button className="icon-btn" onClick={() => {
														setRepeatTask(true)}}>
													{/* <ArrowCounterclockwise color="#23A377" size={24}  /> */}

														<ReplaySvg />
												</button>
											</div>
											{/* <div className="caret-button"> */}
												<button className="icon-btn" onClick={() => goBack()}>
													<ChevronSvg />
													{/* <ChevronLeft color="#FFFFFF" size={24}  /> */}
												</button>
											{/* </div> */}
											{!pauseTask &&
												<button className="icon-btn" onClick={() => {setPauseTask(true)}}>
													{/* <PauseCircleFill color="#23A377" size={48}/> */}
													<PauseSvg />
												</button>
											}
											{pauseTask &&
												<button className="icon-btn" onClick={() => {setPauseTask(false)}}>
													<PlaySvg />
													{/* <PlayCircleFill color="#23A377" size={48}/> */}
												</button>
											}
											{/* <div className="caret-button"> */}
												<button className="icon-btn" onClick={() => {
													currentCourseStore.updateShowPrepareMode(true)
													taskTimerDone()
													}}>
														<ChevronSvg className={'right'} />
													{/* <ChevronRight color="#FFFFFF" size={24}/> */}
												</button>
											{/* </div> */}
										</div>
									</div>
								</div>
								<div className="show-instruction" onClick={() => {
									setOpenInstructionModal(true)
									setPauseTask(true)
								}}>
									<Info size={24} />Instructions
								</div>
								{hasPersonalMsg &&
									<div className="content-personal-msg__wrapper" onClick={() => setOpenPersonalMsgModal(true)}> 
										<div className="avatar">
											<svg width="24" height="24" viewBox="0 0 14 14" fill="#fff" xmlns="http://www.w3.org/2000/svg">
												<path fillRule="evenodd" clipRule="evenodd" d="M3.53567 13.0021C3.37388 12.9248 3.29299 12.8088 3.29299 12.6542V10.3353H1.27067C1.028 10.3353 0.866211 10.1807 0.866211 9.9488V1.23243C0.866211 1.00054 1.028 0.845947 1.27067 0.845947H12.5956C12.8383 0.845947 13.0001 1.00054 13.0001 1.23243V9.9488C13.0001 10.1807 12.8383 10.3353 12.5956 10.3353H7.09494L3.98064 12.9634C3.89975 13.0021 3.77841 13.0407 3.69752 13.0407C3.657 13.0407 3.57611 13.0407 3.53567 13.0021Z" fill="white" stroke="#343434"/>
											</svg>
											{therapistProfileImage && <img src={therapistProfileImage} alt='therapist Profile' />}
										</div>
									</div>
								}
								<PlayVideo repeatDone={() => {setRepeatTask(false)}} repeat={repeatTask} src={currentTask.video.url} pauseVideo={pauseTask} />
							</div>
						:
							<div className="current-task-image">
									<div className="footer-wrapper glow-effect">

									{currentTask?.seconds &&
										<div className="countdown">
											<Timer period={currentTask.seconds} repeatDone={() => {setRepeatTask(false)}} repeat={repeatTask} timerDone={timerDone} pauseTimer={pauseTask} setPlaySound={setPlaySound} playSound={!currentCourseStore.isAutoplayMood} />
										</div>
									}

									<div className="task-component__wrapper">
										<div className="control-buttons__wrapper">
											<div className="repeat-button">
												<button className="icon-btn" onClick={() => {
														setRepeatTask(true)}}>
													{/* <ArrowCounterclockwise color="#23A377" size={24}  /> */}

														<ReplaySvg />
												</button>
											</div>
											{/* <div className="caret-button"> */}
												<button className="icon-btn" onClick={() => goBack()}>
													<ChevronSvg />
													{/* <ChevronLeft color="#FFFFFF" size={24}  /> */}
												</button>
											{/* </div> */}
											{!pauseTask &&
												<button className="icon-btn" onClick={() => {setPauseTask(true)}}>
													{/* <PauseCircleFill color="#23A377" size={48}/> */}
													<PauseSvg />
												</button>
											}
											{pauseTask &&
												<button className="icon-btn" onClick={() => {setPauseTask(false)}}>
													<PlaySvg />
													{/* <PlayCircleFill color="#23A377" size={48}/> */}
												</button>
											}
											{/* <div className="caret-button"> */}
												<button className="icon-btn" onClick={() => {
													currentCourseStore.updateShowPrepareMode(true)
													taskTimerDone()
													}}>
														<ChevronSvg className={'right'} />
													{/* <ChevronRight color="#FFFFFF" size={24}/> */}
												</button>
											{/* </div> */}
										</div>
									</div>
								</div>
								<div className="show-instruction" onClick={() => {
									setOpenInstructionModal(true)
									setPauseTask(true)
								}}>
									<Info size={24} />Instructions
								</div>
								{hasPersonalMsg &&
									<div className="personal-msg__wrapper" onClick={() => setOpenPersonalMsgModal(true)}> 
										<div className="avatar">
											<svg width="24" height="24" viewBox="0 0 14 14" fill="#fff" xmlns="http://www.w3.org/2000/svg">
												<path fillRule="evenodd" clipRule="evenodd" d="M3.53567 13.0021C3.37388 12.9248 3.29299 12.8088 3.29299 12.6542V10.3353H1.27067C1.028 10.3353 0.866211 10.1807 0.866211 9.9488V1.23243C0.866211 1.00054 1.028 0.845947 1.27067 0.845947H12.5956C12.8383 0.845947 13.0001 1.00054 13.0001 1.23243V9.9488C13.0001 10.1807 12.8383 10.3353 12.5956 10.3353H7.09494L3.98064 12.9634C3.89975 13.0021 3.77841 13.0407 3.69752 13.0407C3.657 13.0407 3.57611 13.0407 3.53567 13.0021Z" fill="white" stroke="#343434"/>
											</svg>
											{therapistProfileImage && <img src={therapistProfileImage} alt='therapist Profile' />}
										</div>
									</div>
								}
								<img src={currentTask.image.url} alt={currentTask.image?.title ? currentTask.image.title: currentTask.title} />
							</div>
						}
					</div>
					<div className="content-instruction__wrapper">
						<Instructions />
					</div>

			</div>

			</div>
			
			<Modal
        		show={openPersonalMsgModal}
				onHide={() => {
					// setShow(false)
					// setOpenModal(false)
					// navigate(`/feedback`)
				}}
				size='md'
				centered
			>
				<Modal.Body >
					<div className="personal-msg__modal">
					{/* {hasPersonalMsg && */}
						<div className="personal-msg__wrapper"> 
							<div className="avatar">
								<svg width="24" height="24" viewBox="0 0 14 14" fill="#fff" xmlns="http://www.w3.org/2000/svg">
									<path fillRule="evenodd" clipRule="evenodd" d="M3.53567 13.0021C3.37388 12.9248 3.29299 12.8088 3.29299 12.6542V10.3353H1.27067C1.028 10.3353 0.866211 10.1807 0.866211 9.9488V1.23243C0.866211 1.00054 1.028 0.845947 1.27067 0.845947H12.5956C12.8383 0.845947 13.0001 1.00054 13.0001 1.23243V9.9488C13.0001 10.1807 12.8383 10.3353 12.5956 10.3353H7.09494L3.98064 12.9634C3.89975 13.0021 3.77841 13.0407 3.69752 13.0407C3.657 13.0407 3.57611 13.0407 3.53567 13.0021Z" fill="white" stroke="#343434"/>
								</svg>
								{therapistProfileImage && <img src={therapistProfileImage} alt='therapist Profile' />}
							</div>
							<div className="personal-msg_content">
								{currentTask.generalInfo.personalMsg}
							</div>
						</div>
						<div>
							<button className="btn btn-primary" onClick={() => {
								setOpenPersonalMsgModal(false)
								setPauseTask(false)
							}} >
								Got it!
							</button>
						</div>
					</div>
				</Modal.Body>

			</Modal>
		</div>
	);
};

export default observer(PlayTaskView);