
import React, {useState} from "react";
import { useNavigate } from 'react-router-dom';
import { currentCourseStore } from '../../stores/currentCourseStore';
import { ArrowLeft } from 'react-bootstrap-icons';
import { observer } from "mobx-react-lite";
import ManualModeTask from "./manualModeTask";
import AutoPlayModeTask from "./autoPlayModeTask";
import BackConfirmationModal from "../../modals/backConfirmationModal";
import classNames from "classnames";


// import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const Task = () => {
	const navigate = useNavigate()

	const [openBackConfirmationModal, setOpenBackConfirmationModal] = useState(false)

	const handleBackOnClick = () => {
		if (currentCourseStore.showPlayMode) {
			setOpenBackConfirmationModal(true)
			currentCourseStore.setExercisePause(true)
		} else {
			navigate('/session')
		}
	}
	// const [openFeedbackModal, setOpenFeedbackModal] = useState(false)

	// command

	// const commands = [
	// 	{
	// 	  command: 'Play',
	// 	  callback: () =>  !ShowPrepareView && setPauseTask(false),
	// 	  isFuzzyMatch: true
	// 	},
	// 	{
	// 		command: 'stop',
	// 		callback: () =>  !ShowPrepareView && setPauseTask(true),
	// 		isFuzzyMatch: true,
	// 		// fuzzyMatchingThreshold: 0.6,
	// 	  },
	// 	  {
	// 		command: 'next',
	// 		callback: () =>  !ShowPrepareView && taskTimerDone(),
	// 		isFuzzyMatch: true
	// 	  },
	// 	  {
	// 		command: 'Previous',
	// 		callback: () =>  !ShowPrepareView && goBack(),
	// 		isFuzzyMatch: true
	// 	  },
	// 	  {
	// 		command: 'Start',
	// 		callback: () =>  PrepareTimerDone(),
	// 		isFuzzyMatch: true
	// 	  },
	// 	  {
	// 		command: 'repeat',
	// 		callback: () =>  !ShowPrepareView && setRepeatTask(true),
	// 		isFuzzyMatch: true
	// 	  },
	// 	  {
	// 		command: 'replay previous',
	// 		callback: () =>  repeatPreviousTask(true),
	// 		isFuzzyMatch: true
	// 	  },
	//   ]

	// end of command

	// useSpeechRecognition
	// const {
	// 	transcript,
	// 	listening,
	// 	resetTranscript,
	// 	browserSupportsSpeechRecognition
	//   } = useSpeechRecognition({commands});

	// end of useSpeechRecognition

	// if (browserSupportsSpeechRecognition) {
	// 	SpeechRecognition.startListening()
	// }


	// useEffect(() => {
	// 	if (browserSupportsSpeechRecognition) {
	// 		SpeechRecognition.startListening({ continuous: true })
	// 	}
	// }, [browserSupportsSpeechRecognition, transcript])


	return (
		<div className="task-view-page">
		<div className="row">
			<BackConfirmationModal openModal={openBackConfirmationModal} setOpenModal={setOpenBackConfirmationModal} />
			<div //className="back-to-session" 
			 className={classNames({
				'back-to-session': true,
				'col-12': !currentCourseStore.showPlayMode && !currentCourseStore.showReplayview ,
				'overlap-back': currentCourseStore.showPlayMode || currentCourseStore.showReplayview
			})}
			onClick={() => handleBackOnClick()}>
				<ArrowLeft className="ml-2" color="#000" size={24} />
				<span className="mx-2">Back</span>
			</div>
			{!currentCourseStore.isAutoplayMood ?
				<ManualModeTask />
			:
				<AutoPlayModeTask />
			}
		</div>
		</div>
	);
};

export default observer(Task);