import React, { useRef, useEffect } from "react";

const PlayVideo = ({...props}) => {

    const {src, pauseVideo, repeat} = props
    const videoRef1 = useRef();

    const handlePlay = () => {
      this.videoRef1.current.play();
      this.videoRef1.current.muted = false;
    };
    useEffect(()=>{
        try {

          if(repeat) {
            videoRef1.current.currentTime = 0
            videoRef1.current
            .play()
            .then(() => console.log("run perfectly"))
            .catch((error) => console.log("play promisse fails"));
            props.repeatDone()
          } else {
            if(!pauseVideo) {
              videoRef1.current
              .play()
              .then(() => console.log("run perfectly"))
              .catch((error) => console.log("play promisse fails"));
            } else {
              videoRef1.current
              .pause()
              .then(() => console.log("run perfectly"))
              .catch((error) => console.log("play promisse fails"));
            }
          }

          } catch (error) {
            console.log("try-catch fails");
          }
    }, [videoRef1, pauseVideo, props, repeat, src]);

    return (
      <div onTouchStart={() => handlePlay()}>
          <video
            className="w-100"
            ref={videoRef1}
            id="1"
            // controls
            muted
            loop
            src={src}
          />
      </div>
          
      );
    }

export default PlayVideo
