import React, { useRef, useEffect } from "react";

import prepareEndExercise from '../../assets/audio/prepare_end_exercise.mp3'

const EndExerciseSound = ({...props}) => {
	const { playSound, setPlaySound } = props
	const audioRef1 = useRef();

	const handlePlay = () => {
		audioRef1.current.play();
	};

	useEffect(() => {
		if (playSound) {
			audioRef1.current.currentTime = 0
      audioRef1.current.play();
      setPlaySound(false)
		}

	}, [playSound, setPlaySound]);

	return (
		<div onTouchStart={() => handlePlay()}>
			<audio
				className="d-none"
				ref={audioRef1}
				id="1"
				controls
				loop={false}
				src={prepareEndExercise}
			/>
		</div>

	);
}

export default EndExerciseSound
