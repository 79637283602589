
import React from "react";
const ChevronSvg = (props) => {

  const {className = ''} = props
  return (
    <div className={`chevron-svg ${className}`}>
        <svg width="38" height="38" viewBox="0 0 62 63" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="30.7284" cy="31.5" r="30.7284" transform="rotate(-180 30.7284 31.5)" fill="#23A377"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M33.5547 41.9359L23.7246 32.1057L33.5547 22.2474" fill="#23A377"/>
        <path d="M33.5547 41.9359L23.7246 32.1057L33.5547 22.2474" stroke="white" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </div>
  );
};

export default ChevronSvg;