
import React, {useEffect, useState} from "react";
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import thankYou from '../assets/images/thankYou.png'

const ThankYouModal = (props) => {
    const navigate = useNavigate();


    const {openModal, setOpenModal} = props

    const [show, setShow] = useState(openModal);


    useEffect(() => {
        setShow(openModal)

    }, [openModal]);

  return (
    <Modal
        show={show}
        onHide={() => {
            setShow(false)
            setOpenModal(false)
            navigate(`/courses`)
        }}
        size='lg'
        centered
    >
        <Modal.Body >
        <div className="thank-you-modal__wrapper">
            <div className="title">
                Thank you for your feedback!
            </div>
            <div className="subtitle">
                We'll update your exercise plan based on your responses
            </div>
            <div><img src={thankYou} alt='thank you cover'/></div>
            <div className="mt-4">
				<button className="btn btn-primary" onClick={() => {
                    setShow(false)
                    setOpenModal(false)
                    navigate(`/courses`)
                }}>
                    Continue
				</button>
			</div>
        </div>
        </Modal.Body>

    </Modal>
  );
};

export default observer(ThankYouModal);
