import { useEffect, useState } from "react";

let timeout

const Timer = ({...props}) => {

    const {period, pauseTimer, repeat, repeatDone, timerDone, setPlaySound, playSound = false} = props

    const [seconds, setSeconds] = useState(period);
    let displaySeconds = seconds % 60;
    let displayMinutes = Math.floor(seconds / 60);

    useEffect(()=>{
        if (!pauseTimer && !repeat) {
            if(seconds > 0){
                timeout = setTimeout(() => {
                setSeconds(seconds-1);
                if(playSound && seconds === 2) {
                    setPlaySound(true)
                }
              }, 1000);

            } else {
                timerDone()
                clearTimeout(timeout);
            }
        }
        if(repeat) {
            clearTimeout(timeout);
            setSeconds(period)
            repeatDone()
        }

    }, [repeat, pauseTimer, seconds, period, repeatDone, timerDone, playSound, setPlaySound]);

    return(
        <>
        <div className="d-flex justify-content-center countdown-wrapper">
            <p className="m-0">{("0" + displayMinutes).slice(-2)}</p><p className="m-0">:</p><p className="m-0">{("0" + displaySeconds).slice(-2)}</p>
        </div>
        </>
    )

}

export default Timer