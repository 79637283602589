
import React, {useEffect, useState} from "react";
import { observer } from 'mobx-react-lite';
import Modal from 'react-bootstrap/Modal';

const VideoModal = (props) => {
    const {openModal, setOpenModal, videoSrc} = props
    const [show, setShow] = useState(openModal);

    useEffect(() => {
        setShow(openModal)

    }, [openModal]);

  return (
    <Modal
        show={show}
        onHide={() => {
            setOpenModal(false)
            setShow(false)
        }}
        size='lg'
        centered
    >
        <Modal.Header closeButton />
        <Modal.Body >
        <div className="back-confirmation__wrapper">
            <video width="100%" height="100%" controls autoPlay>
            <source
                src={videoSrc}
                type="video/mp4"
            />
            <source
                src={videoSrc}
                type="video/webm"
            />
            </video>
        </div>
        </Modal.Body>

    </Modal>
  );
};

export default observer(VideoModal);
