import React, { useEffect, useState } from "react";
import { sessionFeedbackApi } from "../../api/feedback";
import ChipComponent from './ChipComponent'
import { Mixpanel } from '../../config/mixpanel';
import { useNavigate } from 'react-router-dom';



const SessionFeedback = (props) => {
	const navigate = useNavigate();


	const {sessionUuid, setContinueToTaskFeedback, therapistProfileImage, therapistName} = props
	useEffect(() => {

	}, []);

    const [selectedChips, updateSelectedChips] = useState([])
	const [textAreaValue, SetTextAreaValue] = useState('')


    const submitSessionFeedback = () => {
        sessionFeedbackApi(sessionUuid, selectedChips, textAreaValue)
		.then((data) => {
			if(data.hasError) {
				navigate('/login')
			}
		})
		setContinueToTaskFeedback(true)
    }

	const handleChipClicked = (chips) => {
		updateSelectedChips(chips)
		Mixpanel.track('Clicked: feedback_session_chip_web', {chip: chips});
	}

	return (
		<div className="feedback__wrapper">
			<div className="title">
				<div className="avatar">
					{therapistProfileImage && <img src={therapistProfileImage} alt='therapist Profile' />}
				</div>
				<div>
					How did you feel about today’s workout? 
				</div>
			</div>
			<div className="subtitle">
				{therapistName} will use your feedback to update your exercise plan
			</div>
			<ChipComponent selectionsArray={selectedChips} updateSelectionsArray={handleChipClicked} />
            <div className="d-flex flex-column mt-5">
                <label>Add a textual note about today’s workout</label>
                <textarea
                    value={textAreaValue}
                    onChange={(e) => {
						SetTextAreaValue(e.target.value)
					}}
                    rows={5}
                    cols={5}
                />
            </div>

			<div className="sticky-bottom glow-effect">
				<button className="btn btn-primary" onClick={() => submitSessionFeedback() }>
					Continue
				</button>
			</div>

		</div>
	);
}

export default SessionFeedback
