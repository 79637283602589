import React, { useEffect, useState } from "react";
import { taskFeedbackApi } from "../../api/feedback";
import ChipComponent from './ChipComponent'
import ThankYouModal from '../../modals/thankYouModal'
import { useNavigate } from 'react-router-dom';


const SingleTaskFeedback = (props) => {
	const {task, setTasksFeedbackdata, tasksFeedbackdata} = props

	const [selections, setSelections] = useState([])
	const [textAreaValue, SetTextAreaValue] = useState('')

	const handleUpdateFeedbackValue = (comment, tags) => {
		if(tasksFeedbackdata.some(item => item.uuid === task.generalInfo.uuid)){
			tasksFeedbackdata.forEach((item) => {
				if(item.uuid === task.generalInfo.uuid) {
					item.comment = comment
					item.tags = tags
				}
			});

			setTasksFeedbackdata([
				...tasksFeedbackdata,
			])

		} else{
			setTasksFeedbackdata([
				...tasksFeedbackdata,
				{
					uuid: task.generalInfo.uuid,
					tags: tags,
					comment: comment

				}
			])
		}
	}

	const updateSelections = (selectedChips) => {
		setSelections(selectedChips)
		handleUpdateFeedbackValue(textAreaValue, selectedChips)
	}

	return(
		<div className="feedback__task">
			<div className="feedback__task-wrapper">
				<div className="feedback__task-image">
				{task?.image?.thumbnailUrl &&
					<img src={task.image.thumbnailUrl} alt={task.title} />
				}
				</div>
				<div className="feedback__task-title">{task.title}</div>
			</div>
			<div className="task-feedback-section">
				<ChipComponent selectionsArray={selections} updateSelectionsArray={updateSelections} isSingleSelection={true} />
				<div className="d-flex flex-column">
					<textarea
						value={textAreaValue}
						onChange={(e) => {
							SetTextAreaValue(e.target.value)
							handleUpdateFeedbackValue(e.target.value, selections)
						}}
						rows={5}
						cols={5}
						placeholder="Add a textual  note about this exercise"
					/>
				</div>
			</div>
		</div>
	)
}

const TaskFeedback = (props) => {
	const navigate = useNavigate();

	const {tasks, therapistProfileImage} = props

	const [tasksFeedbackdata, setTasksFeedbackdata] = useState([])
	const [openThankYouModal, setOpenThankYouModal] = useState(false)
	useEffect(() => {

	}, []);

    const submitTaskFeedback = () => {
		tasksFeedbackdata.forEach((feedback) => {
			taskFeedbackApi(feedback.uuid, feedback.tags, feedback.comment)
			.then((data) => {
				if(data.hasError) {
					navigate('/login')
					return
				}
			})
		})

		setOpenThankYouModal(true)
    }

	return (
		<div className="feedback__wrapper">
			<ThankYouModal openModal={openThankYouModal} setOpenModal={setOpenThankYouModal} />
			<div className="title">
			<div className="avatar">
					{therapistProfileImage && <img src={therapistProfileImage} alt='therapist Profile' />}
				</div>
				<div>
					How did today’s exercises go?
				</div>
			</div>
			<div className="feedback__tasks__wraper">
				{tasks && tasks.map((task, idx) => {
					return(
						<SingleTaskFeedback key={idx} setTasksFeedbackdata={setTasksFeedbackdata} tasksFeedbackdata={tasksFeedbackdata} task={task} />
					)
				})
				}
			</div>

			<div className="sticky-bottom glow-effect">
				<button className="btn btn-primary" onClick={() => submitTaskFeedback() }>
					submit
				</button>
			</div>

		</div>
	);
}

export default TaskFeedback
